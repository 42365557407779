import { Link } from 'react-router-dom';
import { ComponentPart, Part } from 'shared/lib/types/postgres/manufacturing/types';
import { partPath } from '../../lib/pathUtil';
import { getComponentPartLabel, isPartRestricted } from '../lib/parts';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';

type ComponentPartLabelProps = {
  teamId: string;
  component: ComponentPartPartial;
  part?: Part;
};

type ComponentPartPartial = Pick<ComponentPart, 'part_no' | 'name' | 'revision' | 'revision_id'>;

const ComponentPartLabel = ({ teamId, component, part }: ComponentPartLabelProps) => {
  const numberRevLabel = getComponentPartLabel(component, part);
  const nameLabel = component.name || part?.name;

  const partDeleted = !part;
  if (partDeleted) {
    return (
      <div className="p-1">
        <div>{numberRevLabel}</div>
        <div className="text-base">{nameLabel}</div>
      </div>
    );
  }

  // user doesn't have project permissions to view part
  if (isPartRestricted(part)) {
    return (
      <div className="p-1">
        <RestrictedInfo text={RESTRICTED_TEXT} />
      </div>
    );
  }

  const path = partPath(teamId, part.id, component.revision_id);
  return (
    <div className="p-1">
      <Link to={path}>
        <div className="text-sm text-blue-600">{numberRevLabel}</div>
      </Link>
      <div className="text-base">{nameLabel}</div>
    </div>
  );
};

export default ComponentPartLabel;
