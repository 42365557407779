import { useCallback, useEffect, useState } from 'react';

import {
  Hazard,
  Hazard as HazardShared,
  HazardDetails,
  HazardRecord,
} from 'shared/lib/types/testing';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useSettings } from '../../contexts/SettingsContext';

type UseHazardsDeprecatedReturn = {
  isLoading: boolean;
  hazards: Hazard[];
  getHazard: (hazardId: string, versionId?: string) => Promise<Hazard>;
  getAllHazards: () => void;
  listHazardVersions: (hazardId: string) => Promise<Array<HazardRecord>>;
  addHazard: (hazardDetails: HazardDetails) => Promise<Hazard>;
  updateHazard: (hazard: HazardShared) => Promise<void>;
  deleteHazard: (hazardId: string) => Promise<void>;
};

const useHazards = (): UseHazardsDeprecatedReturn => {
  const { isTestConditionsMatrixEnabled } = useSettings();
  const { services: databaseServices, currentTeamId } = useDatabaseServices();

  const [hazards, setHazards] = useState<Array<Hazard>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllHazards = useCallback(() => {
    setIsLoading(true);
    databaseServices.testing
      .getHazards()
      .then((hazards) => {
        setHazards(hazards);
      })
      .catch(() => {
        setHazards([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [databaseServices.testing]);

  useEffect(() => {
    if (!isTestConditionsMatrixEnabled?.()) {
      return;
    }
    getAllHazards();
  }, [
    databaseServices.testing,
    getAllHazards,
    currentTeamId,
    isTestConditionsMatrixEnabled,
  ]);

  const getHazard = useCallback(
    (hazardId: string, versionId?: string) => {
      return databaseServices.testing.getHazard(hazardId, versionId);
    },
    [databaseServices.testing]
  );

  const listHazardVersions = useCallback(
    (hazardId: string) => {
      return databaseServices.testing.listHazardVersions(hazardId);
    },
    [databaseServices.testing]
  );

  const addHazard = useCallback(
    (hazardDetails: HazardDetails) => {
      return databaseServices.testing
        .addHazard(hazardDetails)
        .then(() => getAllHazards());
    },
    [databaseServices.testing, getAllHazards]
  );

  const updateHazard = useCallback(
    (hazard: HazardShared) => {
      return databaseServices.testing
        .updateHazard(hazard)
        .then(() => getAllHazards());
    },
    [databaseServices.testing, getAllHazards]
  );

  const deleteHazard = useCallback(
    (hazardId: string) => {
      return databaseServices.testing
        .deleteHazard(hazardId)
        .then(() => getAllHazards());
    },
    [databaseServices.testing, getAllHazards]
  );

  return {
    isLoading,
    hazards,
    getHazard,
    getAllHazards,
    listHazardVersions,
    addHazard,
    updateHazard,
    deleteHazard,
  };
};

export default useHazards;
