import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { noop } from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { isEmptyValue } from 'shared/lib/text';
import { PartAutoNumbering } from 'shared/lib/types/couch/settings';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import {
  PartBuildItem,
  PartBuildRecordedItem as RecordedItem,
  ReviewPartBuildItem,
} from 'shared/lib/types/views/procedures';
import { StringSelectOption as SelectOption } from '../../lib/formik';
import { Item } from '../lib/inventoryUtil';
import { Location } from '../types';
import CheckedInItemLink from './CheckedInItemLink';
import ComponentPartLabel from './ComponentPartLabel';
import ItemOverlay from './ItemOverlay';
import ItemQuantityInput from './ItemQuantityInput';
import LocationSelect from './LocationSelect';
import FullNumberInput from './Numbering/FullNumberInput';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';

const showItemLink = (recorded?: RecordedItem): boolean => !!recorded?.item_id;

type FieldInputBuildSingleItemSerialProps = {
  part: Part;
  item: PartBuildItem | ReviewPartBuildItem;
  recordedItem?: RecordedItem;
  isEnabled: boolean;
  teamId: string;
  onChangePrefix: (prefix: string) => void;
  onChangeItem: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeLocation: (location: Location) => void;
  onRemoveItem: () => void;
  canRemoveItem: boolean;
  isDuplicateSerialNumber: boolean;
  isExistingSerialNumber: boolean;
  autoNumbering?: PartAutoNumbering;
  onAddSerial?: (itemId?: string, prefix?: string) => void;
  onClearSerial?: (itemId: string | undefined) => void;
  onRecordErrorsChanged: (errorObj: { [key: string]: string }) => void;
  checkedOutItems?: Item[];
};

const FieldInputBuildSingleItemSerial = ({
  part,
  item,
  recordedItem,
  isEnabled,
  teamId,
  onChangePrefix,
  onChangeItem,
  onChangeLocation,
  onRemoveItem,
  canRemoveItem,
  isDuplicateSerialNumber,
  isExistingSerialNumber,
  autoNumbering,
  onAddSerial,
  onClearSerial,
  onRecordErrorsChanged,
  checkedOutItems,
}: FieldInputBuildSingleItemSerialProps) => {
  const prefix = useMemo(() => recordedItem?.prefix ?? item.prefix ?? '', [item.prefix, recordedItem?.prefix]);
  const [serial, setSerial] = useState(() => recordedItem?.serial ?? item.serial ?? '');
  const [showItemOverlay, setShowItemOverlay] = useState(false);

  useEffect(() => {
    setSerial(recordedItem?.serial ?? item.serial ?? '');
  }, [item.serial, recordedItem?.serial]);

  const styleByDiffChangeState =
    'diff_change_state' in item &&
    (item.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED
      ? 'bg-emerald-100 text-emerald-800'
      : item.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED)
      ? 'bg-red-100 line-through text-red-600'
      : '';

  const handleChangePrefix = (option: SelectOption | null) => {
    onChangePrefix(option?.value ?? '');
  };

  const handleChangeItem = (event: ChangeEvent<HTMLInputElement>) => {
    setShowItemOverlay(false);
    onChangeItem(event);
  };

  useEffect(() => {
    if (isEmptyValue(serial.trim())) {
      onRecordErrorsChanged({ serial: 'Empty Serial #' });
    } else {
      onRecordErrorsChanged({});
    }
    // including 'onRecordErrorsChanged' causes an infinite render cycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serial]);

  return (
    <tr className={`h-10 text-sm ${styleByDiffChangeState} items-center`}>
      <td>
        <ComponentPartLabel teamId={teamId} component={item} part={part} />
      </td>
      <td>
        <div className="p-1">
          <div className="w-full max-w-[8rem] flex items-center gap-x-2">
            <ItemQuantityInput
              value={recordedItem?.amount ?? item.amount}
              allowDecimalValue={false}
              onChange={noop}
              disabled={true}
              textSize="sm"
            />
            {part.units && <UnitDisplay unit={part.units} />}
          </div>
        </div>
      </td>
      <td>
        <div className="p-1 pb-2 mt-[0.2rem]">
          {(isEnabled || !showItemLink(recordedItem)) && (
            <div className="relative">
              <FullNumberInput
                type="serials"
                autoNumbering={autoNumbering}
                prefix={prefix}
                onPrefixChange={handleChangePrefix}
                value={serial}
                onInputBlur={handleChangeItem}
                onInputChange={(e) => setSerial(e.target.value)}
                entityId={item.id}
                onAdd={(itemId) => onAddSerial && onAddSerial(itemId, recordedItem?.prefix)}
                onClear={(itemId) => onClearSerial && onClearSerial(itemId)}
                disabled={!isEnabled}
                useFormik={false}
                onNumberInputClick={() => setShowItemOverlay(true)}
                showCaret={checkedOutItems && checkedOutItems.length > 0}
              />
              {showItemOverlay && (
                <ItemOverlay
                  items={checkedOutItems}
                  filter={serial}
                  onSelectItem={(item) => {
                    item.serial && setSerial(item.serial);
                    setShowItemOverlay(false);
                  }}
                />
              )}
            </div>
          )}
          {!isEnabled && showItemLink(recordedItem) && (
            <CheckedInItemLink teamId={teamId} itemId={recordedItem?.item_id as string} trackingId={serial || '--'} />
          )}
          {isDuplicateSerialNumber && <div className="pl-0.5 field-error text-red-700 text-sm">Duplicate Serial #</div>}
          {!isDuplicateSerialNumber && isExistingSerialNumber && (
            <div className="pl-0.5 field-error text-red-700 text-sm">Existing Serial #</div>
          )}
        </div>
      </td>
      <td>
        {part && (
          <div className="max-w-[12rem]">
            <LocationSelect
              locationId={recordedItem?.location_id || ''}
              onChangeLocation={onChangeLocation}
              isDisabled={!isEnabled}
            />
          </div>
        )}
      </td>
      {isEnabled && (
        <td>
          <button
            type="button"
            className="secondary text-gray-400 disabled:text-gray-200"
            onClick={onRemoveItem}
            disabled={!canRemoveItem}
          >
            <FontAwesomeIcon className="ml-1 group-hover:text-gray-500" icon="times-circle" />
          </button>
        </td>
      )}
    </tr>
  );
};

export default FieldInputBuildSingleItemSerial;
