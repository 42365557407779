import React, { useCallback, useMemo } from 'react';
import { Field } from 'formik';
import Select from 'react-select';
import { reactSelectStyles } from '../../lib/styles';
import procedureUtil from '../../lib/procedureUtil';
import stepConditionals, { CONDITIONAL_TYPE } from 'shared/lib/stepConditionals';
import {
  FieldInputBlock,
  SourceType,
  ContentTernaryConditional,
  ContentBinaryConditional,
  Conditional,
} from 'shared/lib/types/views/procedures';

export const CONDITIONAL_SUPPORTED_INPUT_TYPES = ['select', 'list', 'multiple_choice'];

const SourceSelect = ({ disabled, value, onChange, options, field, ariaLabel }) => (
  <div className="w-48">
    <div>
      <Select
        isDisabled={disabled}
        classNamePrefix="react-select"
        styles={reactSelectStyles}
        options={options}
        name={field.name}
        value={value}
        onChange={onChange}
        onBlur={field.onBlur}
        aria-label={ariaLabel}
      />
    </div>
  </div>
);

interface SourceFieldProps {
  content: FieldInputBlock[];
  disabled: boolean;
  onSourceChange: (sourceType: SourceType, contentId: string) => void;
  name: string;
  stepId: string;
  conditional: Conditional;
  includeDuration?: boolean;
}

const SourceField = ({
  content,
  disabled,
  onSourceChange,
  name,
  stepId,
  conditional,
  includeDuration,
}: SourceFieldProps) => {
  const options = useMemo(() => {
    const fieldInputs = procedureUtil.getDisplayConditionalSupportedFieldInputs(content);
    const binaryContent = procedureUtil.getDisplayConditionalSupportedBinaryContent(content);
    const ternaryContent = procedureUtil.getDisplayConditionalSupportedTernaryContent(content);
    const durationOptions: Array<{ value: string; label: string; sourceType: string }> = [];
    if (includeDuration) {
      durationOptions.push({
        value: CONDITIONAL_TYPE.DURATION,
        label: 'Expected Duration',
        sourceType: CONDITIONAL_TYPE.DURATION,
      });
    }

    const inputOptions = fieldInputs.map((block) => ({
      value: block.id,
      label: block.name,
      sourceType: CONDITIONAL_TYPE.CONTENT,
    }));
    const binaryInputOptions = binaryContent.map((block) => ({
      value: block.id,
      label: block.name,
      sourceType: CONDITIONAL_TYPE.CONTENT_BINARY,
    }));
    const ternaryInputOptions = ternaryContent.map((block) => ({
      value: block.id,
      label: block.name,
      sourceType: CONDITIONAL_TYPE.CONTENT_TERNARY,
    }));
    const stepOption = {
      value: stepId,
      label: 'Step',
      sourceType: CONDITIONAL_TYPE.STEP,
    };
    return [stepOption, ...durationOptions, ...inputOptions, ...binaryInputOptions, ...ternaryInputOptions];
  }, [stepId, content, includeDuration]);

  const selectValue = useMemo(() => {
    let value = '';

    if (conditional.source_type === CONDITIONAL_TYPE.STEP) {
      value = conditional.source_id;
    } else if (stepConditionals.isContentConditional(conditional)) {
      const contentConditional: ContentBinaryConditional | ContentTernaryConditional = conditional;
      value = contentConditional.content_id || '';
    } else if (conditional.source_type === CONDITIONAL_TYPE.DURATION) {
      value = CONDITIONAL_TYPE.DURATION;
    }
    return options.find((option) => option.value === value) || '';
  }, [options, conditional]);

  const onChange = useCallback(
    (option) => {
      const contentId =
        (option.sourceType === CONDITIONAL_TYPE.CONTENT ||
          option.sourceType === CONDITIONAL_TYPE.CONTENT_BINARY ||
          option.sourceType === CONDITIONAL_TYPE.CONTENT_TERNARY) &&
        option.value;
      onSourceChange && onSourceChange(option.sourceType, contentId);
    },
    [onSourceChange]
  );

  return (
    <Field
      name={name}
      value={selectValue}
      component={SourceSelect}
      onChange={onChange}
      options={options}
      disabled={disabled}
    />
  );
};

export default SourceField;
