import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { ComponentPart, Part } from 'shared/lib/types/postgres/manufacturing/types';
import PartAndRevisionIdSelect, { PartSelection, getPartAsSelection } from './PartAndRevisionIdSelect';

type PartAndRevisionSelectProps = {
  selectedPart?: Part | null;
  projectId?: string;
  onSelect: (part: Part | null) => void;
  isEnabled?: boolean;
  partSelectLabel?: string;
  partSelectToolTip?: string;
  canClearPart?: boolean;
  shouldDisablePartSelect?: (part: Part | ComponentPart) => boolean;
};

const PartAndRevisionSelect = ({
  selectedPart,
  projectId,
  onSelect,
  canClearPart = true,
  isEnabled = true,
  partSelectLabel,
  partSelectToolTip,
  shouldDisablePartSelect = undefined,
}: PartAndRevisionSelectProps) => {
  const { services } = useDatabaseServices();

  const onPartAndRevisionIdSelect = (selection: PartSelection | null) => {
    if (!selection) {
      onSelect(null);
      return;
    }

    const { part, partRevisionId } = selection;
    const currentRevision = part.revisions?.find((revision) => revision.id === partRevisionId);
    if (currentRevision && part.rev === currentRevision.revision) {
      onSelect(part);
    } else {
      const options = { revisionId: partRevisionId };
      services.manufacturing.getPart(part.id, options).then(onSelect);
    }
  };

  return (
    <PartAndRevisionIdSelect
      selected={getPartAsSelection(selectedPart)}
      projectId={projectId}
      onSelect={onPartAndRevisionIdSelect}
      canClearPart={canClearPart}
      isDisabled={!isEnabled}
      partSelectLabel={partSelectLabel}
      partSelectToolTip={partSelectToolTip}
      shouldDisablePartSelect={shouldDisablePartSelect}
    />
  );
};

export default PartAndRevisionSelect;
