import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FEATURE_OFFLINE_RUN_ACTIONS_ENABLED } from '../config';
import { runUpdated, selectActiveRunById, stepUpdated } from '../contexts/runsSlice';
import externalDataUtil from '../lib/externalDataUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import runUtil from '../lib/runUtil';
import { hasSeveralActions, isOnline } from '../app/offline';
import apm from '../lib/apm';
import { useSettings } from '../contexts/SettingsContext';
import { debounce } from 'lodash';

const useRunObserver = ({ id, isPreviewMode = false }) => {
  const isMounted = useRef(true);
  const { isSubstepDebounceEnabled } = useSettings();
  const { services, currentTeamId } = useDatabaseServices();
  const hasSeveralOfflineActions = useSelector((state) => hasSeveralActions(state));
  const isOffline = useSelector((state) => !isOnline(state));
  //A run document that is managed by the redux store.
  const activeRun = useSelector((state) => selectActiveRunById(state, currentTeamId, id));
  // A run document that is not managed by the redux store (as-runs, etc).
  const [remoteRun, setRemoteRun] = useState({
    doc: null,
    loading: true,
  });
  const [runNotFound, setRunNotFound] = useState(false);
  const [attemptedFetch, setAttemptedFetch] = useState(false);
  const [externalItems, setExternalItems] = useState(null);
  const dispatch = useDispatch();
  const stepUpdatedMap = useMemo(() => {
    return new Map();
  }, []);

  const onStepUpdatedDebounced = useCallback(
    (step, teamId) => {
      if (!stepUpdatedMap.get(step._id)) {
        stepUpdatedMap.set(
          step._id,
          debounce((step, teamId) => {
            dispatch(
              stepUpdated({
                teamId,
                step,
              })
            );
          }, 5000)
        );
      }
      stepUpdatedMap.get(step._id)?.(step, teamId);
    },
    [dispatch, stepUpdatedMap]
  );

  /*
   * This may be counter-intuitive, but we NEED to listen when we are offline
   * so the service worker can provide the offline-cached run data while offline.
   * This is true, even when we have a queue of actions we will want to sync.
   * However, when we go back ONLINE, if there is a queue of actions, we want to
   * pause the observing until they are all synced up to be more efficient
   * and less demanding on memory and renders from observing our OWN sent changes.
   */
  const isObserving = isOffline || !hasSeveralOfflineActions;

  // Flag for detecting when component is unmounted.
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    if (!services.externalData) {
      return;
    }
    // Only load external items once
    if (externalItems) {
      return;
    }
    if (!activeRun && !remoteRun.doc) {
      return;
    }
    const runDoc = activeRun || remoteRun.doc;
    services.externalData.getAllExternalItems(runDoc).then((items) => setExternalItems(items));
  }, [services.externalData, externalItems, activeRun, remoteRun.doc]);

  /**
   * The current run document, from either redux or local React state.
   *
   * Uses the run document from the redux store if available, or a "local" copy
   * if not in redux. Redux store manages active runs or recently active runs,
   * so viewing historic runs requires storing the run doc in local React state.
   */
  const run = useMemo(() => {
    // Ignore redux when offline actions are disabled.
    if (!FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      return remoteRun.doc;
    }

    if (activeRun && attemptedFetch) {
      return activeRun;
    }

    return remoteRun.doc;
  }, [remoteRun, activeRun, attemptedFetch]);

  /**
   * Updated version of run with any "dynamic" data checks. Currently
   * used to fetch external data items when viewing the run.
   *
   * If dynamic data fails to load, we fall back to using the original run.
   */
  const dynamic = useMemo(() => {
    if (!run || !externalItems) {
      return run;
    }
    return externalDataUtil.updateProcedureWithItems(run, externalItems);
  }, [run, externalItems]);

  // Load run and observe changes. (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED: true)
  useEffect(() => {
    // Run effect when offline mode is enabled.
    if (!FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      return;
    }
    if (!id || !services.runs || isPreviewMode) {
      return;
    }

    // Run document change handler.
    const onRunChanged = (run) => {
      if (!isMounted.current) {
        return;
      }

      if (run) {
        dispatch(
          runUpdated({
            run,
            teamId: currentTeamId,
          })
        );
      }
      // Only active runs are managed by the redux store.
      if (run && !runUtil.isRunStateActive(run?.state)) {
        setRemoteRun({
          doc: run,
          loading: false,
        });
      } else {
        setRemoteRun({
          doc: null,
          loading: false,
        });
      }

      setAttemptedFetch(true);
    };

    const onErrorHandler = (err) => {
      if (!isMounted.current) {
        return;
      }
      if (err && err.status === 403) {
        setRunNotFound(true);
      }

      setAttemptedFetch(true);
    };

    if (isObserving) {
      // If actively observing, start the run document observer
      const observer = services.runs.onRunChanged(id, onRunChanged, onErrorHandler);
      return () => {
        observer.cancel();
      };
    }
  }, [id, services.runs, currentTeamId, dispatch, externalItems, isPreviewMode, isObserving]);

  // Load run and observe changes. (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED: false)
  useEffect(() => {
    // Run effect when offline mode is not enabled.
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      return;
    }
    if (!id || !services.runs || isPreviewMode) {
      return;
    }

    // Fetch run document from database.
    const refreshRun = async () => {
      try {
        if (!isMounted.current) {
          return;
        }
        const run = await services.runs.getRun(id);

        setRemoteRun({
          doc: run,
          loading: false,
        });
        return run;
      } catch (err) {
        if (!isMounted.current) {
          return;
        }

        setRemoteRun({
          doc: null,
          loading: false,
        });
        // On initial fetch, set runNotFound to true if it throws a 404.
        if (err && err.status === 404) {
          setRunNotFound(true);
        }
      }
    };

    refreshRun().catch((err) => apm.captureError(err));
    if (isObserving) {
      // If actively observing, start observer and load initial run document.
      const observer = services.runs.onRunChanged(id, refreshRun);
      return () => {
        observer.cancel();
      };
    }
  }, [id, services.runs, externalItems, isPreviewMode, isObserving]);

  // Check for run not found (404).
  useEffect(() => {
    // Run effect when offline mode is enabled.
    if (!FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      return;
    }
    /**
     * TODO: A better way to do this might be to extend `onSelectorChanged` with
     * an onError handler that is invoked with unrecoverable errors like 404.
     * This simple fix to use setRunNotFound will work for now.
     */
    if (!attemptedFetch) {
      return;
    }
    setRunNotFound(!activeRun && !remoteRun.doc);
  }, [activeRun, remoteRun, attemptedFetch]);

  // Fetch step documents and observe changes.
  useEffect(() => {
    if (!id || !services.runs || isPreviewMode) {
      return;
    }

    // Step document change handler.
    const onStepsChanged = (results) => {
      if (!isMounted.current) {
        return;
      }
      for (const step of results.steps) {
        if (isSubstepDebounceEnabled()) {
          onStepUpdatedDebounced(step, currentTeamId);
        } else {
          dispatch(
            stepUpdated({
              teamId: currentTeamId,
              step,
            })
          );
        }
      }
    };

    if (isObserving) {
      // If actively observing, start the step changed observer
      const observer = services.runs.onStepsChanged(id, onStepsChanged);
      return () => {
        observer.cancel();
      };
    }
  }, [
    id,
    services.runs,
    currentTeamId,
    dispatch,
    isPreviewMode,
    isObserving,
    isSubstepDebounceEnabled,
    onStepUpdatedDebounced,
  ]);

  return {
    run: dynamic,
    runNotFound,
  };
};

export default useRunObserver;
