import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import PartAndRevisionSelect from './PartAndRevisionSelect';

interface PartAndRevisionSelectUsingPartsProps {
  selectedPart?: Part | null;
  projectId?: string;
  onChangePart: (part: Part | null) => void;
  isEnabled: boolean;
  partSelectLabel?: string;
  partSelectToolTip?: string;
}

const PartSelect = ({
  selectedPart,
  projectId,
  onChangePart,
  isEnabled = true,
  partSelectLabel,
  partSelectToolTip,
}: PartAndRevisionSelectUsingPartsProps) => {
  return (
    <PartAndRevisionSelect
      selectedPart={selectedPart}
      projectId={projectId}
      onSelect={onChangePart}
      isEnabled={isEnabled}
      partSelectLabel={partSelectLabel}
      partSelectToolTip={partSelectToolTip}
    />
  );
};

export default PartSelect;
