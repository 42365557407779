import { FieldInputMultipleChoiceBlock } from 'shared/lib/types/views/procedures';
import SelectDisplay from './SelectDisplay';
interface RadioGroupDisplayProps {
  block: FieldInputMultipleChoiceBlock;
  referenceId?: string;
}

const RadioGroupDisplay = ({ block, referenceId }: RadioGroupDisplayProps) => {
  return <SelectDisplay block={block} values={block.options} referenceId={referenceId} />;
};

export default RadioGroupDisplay;
