import { Case, TestCaseRun } from 'shared/lib/types/testing';
import { STEP_STATE } from 'shared/lib/runUtil';

const testCaseRunsUtil = {
  getTestCaseStatusForRun: (run: TestCaseRun): string => {
    if (run.step_state === STEP_STATE.FAILED) {
      return 'Failed';
    }
    if (run.step_state === STEP_STATE.COMPLETED) {
      return 'Complete';
    }
    return 'Skipped';
  },

  getStatusBackgroundColorForRun: (run: TestCaseRun): string => {
    if (run.step_state === STEP_STATE.FAILED) {
      return 'bg-red-200';
    }
    if (run.step_state === STEP_STATE.COMPLETED) {
      return 'bg-green-200';
    }
    return 'bg-yellow-200';
  },

  hasIssues: (run: TestCaseRun): boolean =>
    run.issues_metadata && run.issues_metadata.length > 0,

  sortTestCaseFields: (testCase: Case): void => {
    if (testCase.runs) {
      testCase.runs = testCase.runs.sort((a, b) =>
        a.step_finish_time > b.step_finish_time ? -1 : 1
      );
      testCase.runs.forEach((run) => {
        run.issues_metadata = run.issues_metadata.sort((a, b) => {
          if (a.status.status !== b.status.status) {
            return a.status.status === 'draft' ? -1 : 1;
          }
          if (
            (a.severity && b.severity && a.severity.name === b.severity.name) ||
            (!a.severity && !b.severity)
          ) {
            return a.created_at > b.created_at ? -1 : 1;
          }
          if (a.severity && a.severity.name === 'Critical') {
            return -1;
          }
          if (a.severity && a.severity.name === 'Major') {
            return b.severity && b.severity.name === 'Critical' ? 1 : -1;
          }
          if (a.severity && a.severity.name === 'Minor') {
            return !b.severity ? -1 : 1;
          }
          return 1;
        });
      });
    }
  },
};

export default testCaseRunsUtil;
