import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import Button, { BUTTON_TYPES } from './Button';
import { selectOfflineInfo } from '../app/offline';

const HEADER_TEXT = "You're offline";
const BODY_TEXT =
  "You don't appear to be connected to the internet. To prevent losing unsaved changes, wait until reconnecting to continue editing.";

const BlockEditsModal = () => {
  const online = useSelector((state) => selectOfflineInfo(state).online);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(!online);
  }, [online]);

  const onClose = () => setVisible(false);

  return (
    <Dialog
      className="w-1/2"
      header={HEADER_TEXT}
      visible={visible}
      onHide={onClose}
      footer={<Footer onClose={onClose} />}
      closable={false}
    >
      <div className="mt-4">{BODY_TEXT}</div>
    </Dialog>
  );
};

type FooterProps = {
  onClose: () => void;
};

const Footer = ({ onClose }: FooterProps) => {
  return (
    <Button type={BUTTON_TYPES.PRIMARY} title="Close" ariaLabel="Close" onClick={onClose}>
      Close
    </Button>
  );
};

export default BlockEditsModal;
