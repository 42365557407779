import { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldStepTimer from '../FieldStepTimer';
import FieldStepDuration from '../FieldStepDuration';
import { STEP_STATE } from 'shared/lib/runUtil';
import { Duration } from 'luxon';
import ReviewDependencies from './ReviewDependencies';
import ProcedureFieldDiff from '../ProcedureFieldDiff';
import sharedDiffUtil from 'shared/lib/diffUtil';

const ReviewProcedureStepBanner = ({
  step,
  hasDependencies,
  onRefChanged,
  scrollMarginTopValueRem,
  updateStepDetail,
  baseRunningCondition,
  isStepActive,
  stepState,
}) => {
  const timerDurationStarted = useMemo(() => {
    if (step.duration && typeof step.duration === 'object') {
      if (step.duration.started_at) {
        return true;
      } else {
        return false;
      }
    }

    if (step.timer && typeof step.timer === 'object') {
      if (step.timer.started_at) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }, [step.timer, step.duration]);

  const bgColor = useMemo(() => {
    if (hasDependencies) {
      return 'bg-gray-100';
    } else if (stepState === STEP_STATE.FAILED) {
      return 'bg-red-200';
    } else if (stepState === STEP_STATE.SKIPPED) {
      return 'bg-app-gray-400';
    } else if (stepState === STEP_STATE.COMPLETED || (step.timer && step.timer.completed)) {
      return 'bg-app-green-200';
    } else if (timerDurationStarted) {
      return 'bg-blue-100';
    } else {
      return 'bg-white';
    }
  }, [hasDependencies, stepState, step.timer, timerDurationStarted]);

  const timerMessage = useCallback(
    (getNew = true) => {
      if (!step.timer) {
        return '';
      }

      let output = 'Start Timer';
      if (step.timer.completed) {
        output = 'Timer Elapsed';
      } else if (step.timer.started_at) {
        output = 'Timer Running';
      }

      let timeLeft = step.timer.time_left;
      if (!timeLeft) {
        return output;
      }

      if (getNew) {
        timeLeft = sharedDiffUtil.getDiffValue(step.timer, 'time_left', 'new');
      } else {
        timeLeft = sharedDiffUtil.getDiffValue(step.timer, 'time_left', 'old');
      }

      const formattedTimeLeft = Duration.fromISO(timeLeft).toFormat('hh:mm:ss');
      return `${output} (${formattedTimeLeft})`;
    },
    [step.timer]
  );

  return (
    <tbody
      data-testid="header"
      className={`${bgColor} border-2 border-slate-300 shadow z-0`}
      ref={onRefChanged}
      style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
      aria-label="Step Header"
      role="region"
    >
      <tr>
        <td colSpan={3} className="py-2 pr-2">
          <div className="w-full flex flex-row justify-between">
            <div className="flex flex-row flex-grow">
              {step.requires_previous && (
                <span className="ml-4 mr-3 font-semibold text-xs text-gray-600 whitespace-nowrap">
                  Requires Previous Step
                </span>
              )}
              {step['duration'] && typeof step['duration'] === 'object' && (
                <div className="ml-2 mt-1">
                  <FontAwesomeIcon icon="stopwatch" />
                  <span className="ml-1 text-gray-600">{step.duration.started_at ? 'Step Started' : 'Start Step'}</span>
                </div>
              )}
              {step['timer'] && typeof step['timer'] === 'object' && (
                <div className="ml-2 mt-1">
                  <FontAwesomeIcon icon="hourglass-start" />
                  <span className="ml-1 mr-3 text-gray-600">
                    <ProcedureFieldDiff original={timerMessage()} redlined={timerMessage(false)} />
                  </span>
                </div>
              )}
              {hasDependencies && (
                <div className="ml-2">
                  <ReviewDependencies dependencies={step.dependencies} blockId={step.id} />
                </div>
              )}
            </div>
            <div className="flex flex-row items-center">
              {step['duration'] && typeof step['duration'] === 'object' && (
                <FieldStepDuration
                  value={step['duration']}
                  disabled={!baseRunningCondition}
                  hideStartButton={typeof step['timer'] === 'object'}
                  updateStepDetail={updateStepDetail}
                  isPaused={!isStepActive}
                />
              )}
              {step['timer'] && typeof step['timer'] === 'object' && (
                <FieldStepTimer
                  value={step['timer']}
                  disabled={!baseRunningCondition}
                  updateStepDetail={updateStepDetail}
                />
              )}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default ReviewProcedureStepBanner;
