import superlogin from './superlogin';
import { changes, resourceChanges } from './lib/changes';
import find from './lib/find';
import onNetworkChanged from './lib/network';
import retry from './lib/retry';

/**
 * observers.js
 *
 * Better realtime listeners for couchdb realtime data.
 *
 * PouchDB doesn't implement retry functionality for the .changes feed, so
 * we implement our own here. These observers use retry with exponential
 * backoff, and also listen for network online events to retry immediately.
 *
 * Reference: https://github.com/pouchdb/pouchdb/issues/6151
 */

/**
 * @callback ChangesFn
 * @param   {Function} changeHandler - A change handler callback.
 * @param   {Function} errorHandler  - An error handler callback.
 * @returns {Function}               - A changes.js change listener.
 */

/**
 * @callback RequestorFn
 * @param   {Array<string>} [ids] - Array of resource IDs to request
 * @returns {Object} - A resource, from couchdb or the API or other.
 */

/**
 * Generic change listener for a resource.
 *
 * Handles network failures with exponential backoff, and provides the initial
 * resource when the change feed is first started. Can be cancelled.
 *
 * If `requestor` is provided, `onChange` will be provided the results of the
 * resource request, otherwise it will be provided the changes object.
 *
 * @param {ChangesFn} changes - A changes callback to get the changes. This
 *   callback is provided change and error handler callbacks, and is expected
 *   to return a custom changes function from changes.js.
 * @param {RequestorFn | null} requestor - A callback for fetching the latest
 *   resource data when a change occurs.
 * @param {Function} onChange - Callback to receive changes or resource object.
 * @param {Function | null} onError - Called with error object.
 * @returns {Object} - Object with function 'cancel()' to cancel the listener.
 */
const onBaseChanged = (changes, requestor, onChange, onError = null) => {
  let observer = null;
  let networkListener = null;
  let retries = 0;
  let cancelled = false;

  const changeHandler = async (changeResult) => {
    try {
      let results = null;
      if (requestor) {
        // Fetch the latest version of the resource.
        const ids = changeResult?.results?.map((result) => result.id);
        results = await requestor(ids);
      } else {
        results = changeResult;
      }

      /**
       * Successfully received the change feed event and retrieved the
       * updated document(s). Reset the retry counter.
       */
      retries = 0;

      // Notify observers with latest document(s).
      if (onChange) {
        onChange(results);
      }
    } catch (error) {
      // Restart back at the changes feed if we fail to fetch the docs.
      errorHandler(error);
    }
  };

  const errorHandler = (error) => {
    retries += 1;
    // Clear change observer to give network observer a chance to run early.
    if (observer) {
      observer.cancel();
    }
    observer = null;

    // Notify onError callback if it was provided.
    onError && onError(error);

    /**
     * Retry on any error, indefinitely.
     *
     * In the future we may want to think about detecting "permanent" failures.
     * Since the worst is a max retry every 30sec, we should be OK, and we
     * have the .cancel() method to allow stopping this loop.
     */
    retry(retries, getNextChange);
  };

  const getNextChange = () => {
    // Ignore if a change feed has already been started.
    if (observer || cancelled) {
      return;
    }
    observer = changes(changeHandler, errorHandler);
  };

  // Observe network connectivity changes to shortcut the retry schedule.
  networkListener = onNetworkChanged(({ online }) => {
    if (online) {
      getNextChange();
    }
  });

  // Start the observer flow.
  getNextChange();

  // Return object used to cancel this observer.
  return {
    cancel: () => {
      if (observer) {
        observer.cancel();
      }
      if (networkListener) {
        networkListener.cancel();
      }
      cancelled = true;
    },
  };
};

/**
 * Realtime document change listener to observe document changes by selector.
 *
 * For additional efficiency, the realtime listener can be filtered by the
 * given `docIds`. When the change feed fires, the given `selector` will run
 * to fetch the updates. This allows for many kinds of flexible realtime
 * listener and selector combinations. Note however, there is no attempt to
 * ensure consistency between `docIds` and `selector` - the caller must ensure
 * that all documents referenced in the selector are included in `dicIds`. If
 * this is not feasible, use an empty `docIds` to observe all database changes.
 *
 * Retries on any error or network failure, with exponential backoff. Retries
 * continue indefinitely, so remember to call .cancel() when finished.
 *
 * @param {String} name - Name of couchdb database.
 * @param {Array | null} docIds -  (Optional) List of document ids to filter the change feed.
 * @param {Object} selector -  (Optional) Selector to use when retrieving the updated document(s).
 * @param {Function} onChange - Called with couchdb change object.
 * @param {Function | null} onError - Called when there was an error fetching the change.
 * @param {RequestorFn | null} customRequestor - Function to bypass the _find operation going directly to couchDB
 * @returns {Object} - Object with function 'cancel()' to cancel the listener.
 */
const onSelectorChanged = (name, docIds, selector, onChange, onError = null, customRequestor = null) => {
  let requestor = null;
  if (customRequestor) {
    requestor = customRequestor;
  } else if (selector) {
    requestor = () => find(name, selector, null, null, null);
  }
  const _changes = (changeHandler, errorHandler) => changes(name, docIds, changeHandler, errorHandler);
  return onBaseChanged(_changes, requestor, onChange, onError);
};

/**
 * Document change listener for a specific document.
 *
 * Handles network failures with exponential backoff, and gives the initial
 * document when started. Can be cancelled. This is a helper method that uses
 * `onSelectorChanged` for a single document.
 *
 * @param {String} name - Name of couchdb database.
 * @param {String} docId - Document id to observe for changes.
 * @param {Function} onChange - Called with couchdb change object.
 * @param {Function | null} onError - Called with error object.
 * @param {RequestorFn | null} customRequestor - Function to bypass the _find operation going directly to couchDB
 * @returns {Object} - Object with function 'cancel()' to cancel the listener.
 */
const onDocChanged = (name, docId, onChange, onError = null, customRequestor = null) => {
  const selector = { _id: docId };
  const _onChange = (docs) => {
    onChange && onChange(docs && docs[0]);
  };
  return onSelectorChanged(name, [docId], selector, _onChange, onError, customRequestor);
};

/**
 * Resource change listener for an API changes endpoint.
 *
 * Handles network failures with exponential backoff, and provices the initial
 * changes object when started. Can be cancelled.
 *
 * If `resourceUrl` is provided, `onChange` will be provided the results of the
 * resource request, otherwise it will be provided the changes object.
 *
 * @param {String} url - Full API path for a changes endpoint.
 * @param {String} resourceUrl - (Optional) ).
 * @param {Function} onChange - Callback to receive changes or resource object.
 * @param {Function | null} onError - Called with error object.
 * @returns {Object} - Object with function 'cancel()' to cancel the listener.
 */
const onResourceChanged = (url, resourceUrl, onChange, onError = null) => {
  let requestor = null;
  if (resourceUrl) {
    requestor = (ids) =>
      superlogin
        .getHttp()
        .get(resourceUrl, { params: { ids } })
        .then((response) => response.data);
  }
  const _changes = (changeHandler, errorHandler) => resourceChanges(url, changeHandler, errorHandler);
  return onBaseChanged(_changes, requestor, onChange, onError);
};

export { onSelectorChanged, onDocChanged, onResourceChanged };
