import { useCallback, useEffect, useRef, useState } from 'react';
import {
  getScrollToArguments,
  getScrollToParamsFromUrlSearchParams,
} from '../lib/scrollToUtil';
import { useHistory } from 'react-router-dom';
import { Procedure, Run } from 'shared/lib/types/views/procedures';
import { VIEW_MODES } from '../components/FieldSetViewModeEditSelect';
import { INTRO_STEP_KEY } from '../contexts/RunContext';

interface useUrlScrollToProps {
  procedure: Procedure | Run | null;
  searchParams: URLSearchParams;
  setIsCollapsed?: (id: string, collapsed: boolean) => void;
  expandRedlineComments?: (id: string, expanded?: boolean) => void;
  setShowStepAction?: (id: string) => void;
  viewMode?: string | null;
  onAfterScroll?: () => void;
}

type scrollToIdParams = {
  stepId: string;
  scrollToId?: string;
  headerId?: string;
  sectionId?: string;
  commentId?: string;
};

interface useUrlScrollToReturns {
  onScrollToRefChanged: (id: string, element: HTMLElement) => void;
  onScrollToId: (params: scrollToIdParams) => void;
}

type scrollToAction = {
  id?: string;
};

const useUrlScrollTo = ({
  procedure,
  searchParams,
  setIsCollapsed,
  expandRedlineComments,
  setShowStepAction,
  viewMode,
  onAfterScroll,
}: useUrlScrollToProps): useUrlScrollToReturns => {
  const history = useHistory();
  const scrollToRefs = useRef({});
  const [scrollToAction, setScrollToAction] = useState<scrollToAction>({
    id: undefined,
  });

  // Called when element refs are changed, will scroll to element if element exists and scrollToId matches element id.
  const onScrollToRefChanged = useCallback(
    (id: string, element: HTMLElement) => {
      scrollToRefs.current[id] = element;

      if (element && scrollToAction.id && scrollToAction.id === id) {
        // Set scroll inside a timeout, to wait for the ui updates to complete before scrolling to the elements final position.
        setTimeout(() => {
          // Browser default is auto.
          element.scrollIntoView({
            behavior: 'smooth',
          });
        });
        setScrollToAction({
          id: undefined,
        });
        onAfterScroll && onAfterScroll();
      }
    },
    [scrollToRefs, scrollToAction, onAfterScroll]
  );

  const onScrollToId = useCallback(
    ({
      stepId,
      scrollToId,
      headerId,
      sectionId,
      commentId,
    }: scrollToIdParams) => {
      if (setIsCollapsed) {
        if (sectionId) {
          setIsCollapsed(sectionId, false);
        }

        if (headerId) {
          setIsCollapsed(headerId, false);
        }

        if (stepId) {
          setIsCollapsed(stepId, false);
        }
      }

      if (commentId && expandRedlineComments) {
        expandRedlineComments(stepId);
      }

      if (viewMode === VIEW_MODES.SINGLE_CARD && setShowStepAction) {
        if (scrollToId === INTRO_STEP_KEY) {
          setShowStepAction(INTRO_STEP_KEY);
        } else {
          setShowStepAction(headerId ? INTRO_STEP_KEY : stepId);
        }
      }
      setScrollToAction({
        id: scrollToId,
      });
    },
    [expandRedlineComments, setIsCollapsed, setShowStepAction, viewMode]
  );

  /**
   * Watches the url for scrollId and scrollType search params.
   * If matching params are found, it will scroll to specified DOM element and clear the search params.
   */
  useEffect(() => {
    if (!procedure) {
      return;
    }

    const scrollToParams = getScrollToParamsFromUrlSearchParams(searchParams);
    if (!scrollToParams) {
      return;
    } else {
      // Clear search params when rendered, to prevent scroll params from interfering with other scrollTo calls.
      history.replace({
        search: '',
      });
    }

    const scrollToArguments = getScrollToArguments({
      id: scrollToParams.id,
      type: scrollToParams.type,
      procedure,
    });
    onScrollToId(scrollToArguments);
  }, [searchParams, onScrollToId, procedure, history]);

  return { onScrollToRefChanged, onScrollToId };
};

export default useUrlScrollTo;
