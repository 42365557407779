import { CouchLikeOperation } from 'shared/lib/types/operations';
import { RunMap } from './types';
import NoDataPlaceholder from './NoDataPlaceholder';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import runUtil from '../../../lib/runUtil';
import RunProgressBar, { StepCounts } from '../../RunProgressBar';
import { runViewPath } from '../../../lib/pathUtil';
import RunStatusLabel from '../../RunStatusLabel';
import RunLabel from '../../RunLabel';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';

const RunningEventStatusColumn = ({
  event,
  runMap,
  operation,
}: {
  event: Event;
  runMap: RunMap;
  operation: CouchLikeOperation;
}) => {
  const { currentTeamId }: { currentTeamId: string } = useDatabaseServices();
  if (!event.run_id) {
    return <NoDataPlaceholder />;
  }
  const run = runMap[event.run_id];
  const status = runUtil.getStatus(run.state, run.status);
  const stepCounts = runUtil.getRunStepCounts(run).runCounts as StepCounts;
  if (!run || !status) {
    return <NoDataPlaceholder />;
  }
  const linkPath = {
    pathname: runViewPath(currentTeamId, event.run_id),
    state: { operation: operation.key },
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-x-2">
        <RunStatusLabel statusText={status} />
        <RunLabel code={run.code} runNumber={run.run_number} link={linkPath} truncateAtCodeChars={10} />
      </div>
      <div className="flex flex-row self-start w-44">
        <RunProgressBar runStatus={{ id: run._id, status, state: 'running' }} stepCounts={stepCounts} />
      </div>
    </div>
  );
};

export default RunningEventStatusColumn;
