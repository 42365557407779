import { useState, useMemo, useCallback } from 'react';

export interface Section {
  id: string;
  steps: Array<Step>;
}

export interface Step {
  id: string;
}

export interface ExpandCollapseProps {
  isCollapsedMap: { [id: string]: boolean };
  setIsCollapsedMap: (map: { [id: string]: boolean }) => void;
  areAllExpanded: boolean;
  setAllExpanded: (
    expanded: boolean,
    sectionIds: Array<string>,
    headerIds: Array<string>
  ) => void;
  setIsCollapsed: (id: string, collapsed: boolean) => void;
  setIsCollapsedMultiple: (ids: Array<string>, collapsed: boolean) => void;
  areAllStepsInSectionExpanded: (section: Section) => boolean;
  setAllStepsInSectionExpanded: (expanded: boolean, section: Section) => void;
  areRedlineCommentsExpanded: (stepId: string) => boolean;
  expandRedlineComments: (stepId: string, areExpanded?: boolean) => void;
}

const useExpandCollapse = (
  onSetIsCollapsed = () => {
    /** Noop */
  }
): ExpandCollapseProps => {
  const [isCollapsedMap, setIsCollapsedMap] = useState({});
  const [redlineCommentExpandedMap, setRedlineCommentExpandedMap] = useState(
    {}
  );

  const areAllExpanded = useMemo(() => {
    for (const key in isCollapsedMap) {
      if (isCollapsedMap[key]) {
        return false;
      }
    }

    return true;
  }, [isCollapsedMap]);

  const setIsCollapsed = useCallback(
    (id: string, collapsed: boolean) => {
      setIsCollapsedMap((state) => {
        const newState = { ...state };

        newState[id] = collapsed;
        return newState;
      });

      if (onSetIsCollapsed) {
        onSetIsCollapsed();
      }
    },
    [onSetIsCollapsed]
  );

  const setIsCollapsedMultiple = useCallback(
    (ids: Array<string>, collapsed: boolean) => {
      setIsCollapsedMap((state) => {
        const newState = { ...state };
        for (const id of ids) {
          newState[id] = collapsed;
        }
        return newState;
      });

      if (onSetIsCollapsed) {
        onSetIsCollapsed();
      }
    },
    [onSetIsCollapsed]
  );

  const setAllExpanded = useCallback(
    (
      expanded: boolean,
      sectionIds: Array<string>,
      headerIds: Array<string>
    ) => {
      setIsCollapsedMap((state) => {
        const newState = { ...state };
        for (const key in state) {
          newState[key] = false;
        }
        if (!expanded) {
          const headersAndSectionsToCollapse = [...sectionIds, ...headerIds];
          for (const id of headersAndSectionsToCollapse) {
            newState[id] = true;
          }
        }
        return newState;
      });
    },
    []
  );

  const areAllStepsInSectionExpanded = useCallback(
    (section: Section) =>
      (section.steps as Array<Step>).every((step) => !isCollapsedMap[step.id]),
    [isCollapsedMap]
  );

  const setAllStepsInSectionExpanded = useCallback(
    (expanded: boolean, section: Section) =>
      setIsCollapsedMultiple(
        section.steps.map((step) => step.id),
        !expanded
      ),
    [setIsCollapsedMultiple]
  );

  const areRedlineCommentsExpanded = useCallback(
    (stepId: string) => Boolean(redlineCommentExpandedMap[stepId]),
    [redlineCommentExpandedMap]
  );

  const expandRedlineComments = useCallback(
    (stepId: string, areExpanded = true) =>
      setRedlineCommentExpandedMap((commentMap) => ({
        ...commentMap,
        [stepId]: areExpanded,
      })),
    []
  );

  return {
    isCollapsedMap,
    setIsCollapsedMap,
    areAllExpanded,
    setAllExpanded,
    setIsCollapsed,
    setIsCollapsedMultiple,
    areAllStepsInSectionExpanded,
    setAllStepsInSectionExpanded,
    areRedlineCommentsExpanded,
    expandRedlineComments,
  };
};

export default useExpandCollapse;
