import {
  AdditionalValidationInfo,
  ContentBlockValidateMapping,
} from './types/blockTypes';
import { StepBlock } from './types/views/procedures';

export async function processContentBlock({
  mapping,
  block,
  additionalInfo,
}: {
  mapping: ContentBlockValidateMapping;
  block: StepBlock;
  additionalInfo: AdditionalValidationInfo;
}): Promise<any> {
  const blockType = block.type.toLowerCase();
  const mappedBlock = mapping[blockType] as (
    block,
    additionalInfo
  ) => Promise<any>;
  // Should never be true unless there is a developer error
  if (!mappedBlock) {
    console.log(`Unknown block type ${blockType}`);
    throw new Error(`Unknown block type ${blockType}`);
  }
  return mappedBlock(block, additionalInfo);
}
