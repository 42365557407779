import { Run } from 'shared/lib/types/views/procedures';
import { FrontendEvent as Event, LinkedProcedureNode } from 'shared/schedule/types/event';
import { DateTime } from 'luxon';
import { CouchLikeOperations, CouchLikeOperationsSummary } from 'shared/lib/types/operations';
import { OperationFormValues } from '../OperationFormModal/types';
import labels from 'shared/lib/labelUtil';
import { LinkedProcedureRow } from '../EventList/types';
import idUtil from '../../../lib/idUtil';

interface RunMap {
  [runId: string]: Run;
}

export const compareEventStartTimes = (a: Event, b: Event, runMap: RunMap): number => {
  const aRun = runMap[a.run_id || ''];
  const bRun = runMap[b.run_id || ''];
  const aTime = aRun?.starttime || (a.start as DateTime)?.toUTC().toISO() || '';
  const bTime = bRun?.starttime || (b.start as DateTime)?.toUTC().toISO() || '';
  return aTime < bTime ? -1 : aTime > bTime ? 1 : 0;
};

const REQUIRED_MSG = 'Required.';
export const NAME_EXISTS_MSG = 'Operation already exists.';
const INVALID_CHAR_MSG = '"%" is not allowed.';

export const validateOperationForm = (
  values: OperationFormValues,
  operations: CouchLikeOperations | CouchLikeOperationsSummary | null
) => {
  const errors: { name?: string } = {};
  if (!values.name || !values.name.trim()) {
    errors.name = REQUIRED_MSG;
  }
  if (values.name.indexOf('%') !== -1) {
    errors.name = INVALID_CHAR_MSG;
  }
  // Check that operation name is unique
  const key = labels.getLabelKey(values.name);
  if (operations?.operations[key]) {
    errors.name = NAME_EXISTS_MSG;
  }
  return errors;
};

export const getAllLinkedProcedureIds = (events: Array<Event>): Array<{ id: string }> => {
  const uniqueIds = new Set<string>();

  for (const event of events) {
    if (event.procedure_id) {
      uniqueIds.add(event.procedure_id);
    }
    for (const linkedProcedure of event.linked_procedures || []) {
      const allIds = _getLinkedProcedureIds(linkedProcedure);
      allIds.forEach((id) => uniqueIds.add(id));
    }
  }
  return Array.from(uniqueIds).map((id) => ({ id }));
};

const _getLinkedProcedureIds = (linkedProcedure: LinkedProcedureNode): Array<string> => {
  const ids: string[] = [linkedProcedure.procedure_id];

  for (const child of linkedProcedure.linked_procedures) {
    ids.push(..._getLinkedProcedureIds(child));
  }
  return ids;
};

export const processEventsWithLinkedProcedures = (events: Event[]): Array<Event | LinkedProcedureRow> => {
  const result: Array<Event | LinkedProcedureRow> = [];

  for (const event of events) {
    if (!event.is_root_event) {
      continue;
    }
    result.push(event);

    (event.linked_procedures || []).forEach((linkedProcedure, index) => {
      const isRightBranch = index === (event.linked_procedures?.length || 0) - 1;
      const initialRightBranchDepths = isRightBranch ? [0] : [];

      const allLinkedProceduresWithDepth = _processAllLinkedProcedureNodes(
        linkedProcedure,
        1,
        event.run_id ?? undefined,
        initialRightBranchDepths
      );

      allLinkedProceduresWithDepth.forEach(
        ({ contentBlockId, procedureId, sectionId, depth, eventId, runId, parentRunId, rightMostDepths }) => {
          result.push({
            id: eventId ? eventId : idUtil.generateUuidEquivalentId(),
            content_block_id: contentBlockId,
            procedure_id: procedureId,
            section_id: sectionId,
            event_id: eventId,
            run_id: runId,
            parent_run_id: parentRunId,
            depth,
            is_linked_procedure: true,
            right_most_depths: rightMostDepths,
          });
        }
      );
    });
  }

  return result;
};

const _processAllLinkedProcedureNodes = (
  linkedProcedureNode: LinkedProcedureNode,
  depth: number,
  parentRunId: string | undefined,
  rightBranchDepths: number[] = []
): Array<{
  contentBlockId: string;
  procedureId: string;
  sectionId: string | undefined;
  eventId: string | undefined;
  runId: string | undefined;
  parentRunId: string | undefined;
  depth: number;
  rightMostDepths: number[];
}> => {
  const isLeafNode = linkedProcedureNode.linked_procedures.length === 0;

  const results = [
    {
      contentBlockId: linkedProcedureNode.content_block_id,
      parentRunId,
      depth,
      procedureId: linkedProcedureNode.procedure_id,
      sectionId: linkedProcedureNode.section_id,
      eventId: linkedProcedureNode.event_id,
      runId: linkedProcedureNode.run_id,
      rightMostDepths: isLeafNode ? rightBranchDepths : [],
    },
  ];

  linkedProcedureNode.linked_procedures.forEach((child, index) => {
    const isRightBranch = index === linkedProcedureNode.linked_procedures.length - 1;
    const updatedRightBranchDepths = isRightBranch ? [...rightBranchDepths, depth] : [];
    results.push(
      ..._processAllLinkedProcedureNodes(child, depth + 1, linkedProcedureNode.run_id, updatedRightBranchDepths)
    );
  });

  return results;
};
