import { CouchLikeOperation } from 'shared/lib/types/operations';
import { runViewPath } from '../../../lib/pathUtil';
import RunStatusLabel from '../../RunStatusLabel';
import RunLabel from '../../RunLabel';
import NoDataPlaceholder from './NoDataPlaceholder';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import { RunState, RunStatus } from 'shared/lib/types/views/procedures';
import { RunMap } from './types';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';

const CompletedEventStatusColumn = ({
  event,
  status,
  runMap,
  operation,
}: {
  event: Event;
  status: RunState | RunStatus | undefined;
  runMap: RunMap;
  operation: CouchLikeOperation;
}) => {
  const { currentTeamId }: { currentTeamId: string } = useDatabaseServices();
  if (!event.run_id) {
    return <NoDataPlaceholder />;
  }
  const run = runMap[event.run_id];
  if (!run || !status) {
    return <NoDataPlaceholder />;
  }
  const linkPath = {
    pathname: runViewPath(currentTeamId, event.run_id),
    state: { operation: operation.key },
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-x-2">
        <RunStatusLabel statusText={status} />
        <RunLabel code={run.code} runNumber={run.run_number} link={linkPath} />
      </div>
    </div>
  );
};

export default CompletedEventStatusColumn;
