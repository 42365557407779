import { useMemo } from 'react';
import revisions from '../lib/revisions';
import { REDLINE_TYPE } from 'shared/lib/redlineUtil';

export const getLatestApprovedBlock = ({ block, redlines }) => {
  let idx = redlines ? redlines.length : 0;
  while (idx--) {
    if (redlines[idx].block && !redlines[idx].pending) {
      return redlines[idx].type === REDLINE_TYPE.FULL_STEP_REDLINE ? block : redlines[idx].block;
    }
  }
  return block;
};

const useBlockRedlines = ({ block, redlines }) => {
  const latestRedline = useMemo(() => redlines && redlines.length > 0 && redlines[redlines.length - 1], [redlines]);

  const latestApprovedBlock = useMemo(() => {
    return getLatestApprovedBlock({ block, redlines });
  }, [block, redlines]);

  const latestBlock = useMemo(() => {
    if (latestRedline && latestRedline.block) {
      return latestRedline.block;
    }
    return block;
  }, [block, latestRedline]);

  const hasPendingRedlines = useMemo(() => latestRedline && latestRedline.pending, [latestRedline]);

  const hasRedlines = useMemo(
    () => hasPendingRedlines || (latestRedline && !revisions.isBlockEqual(latestRedline.block, block)),
    [block, latestRedline, hasPendingRedlines]
  );

  const allRedlineUserIds = useMemo(() => {
    if (!redlines) {
      return [];
    }
    // Get unique userIds from redline data
    return redlines.map((redline) => redline.userId).filter((value, index, self) => self.indexOf(value) === index);
  }, [redlines]);

  return {
    latestBlock,
    latestApprovedBlock,
    latestRedline,
    hasRedlines,
    allRedlineUserIds,
    hasPendingRedlines,
  };
};

export default useBlockRedlines;
