import { omit } from 'lodash';
import { ItemOccurrenceInRun } from 'shared/lib/types/api/manufacturing/items/models';
import {
  PurchaseOrderDoc as PurchaseOrder,
  PurchaseOrderState,
} from 'shared/lib/types/postgres/manufacturing/orders';
import {
  Part,
  PartDetail,
  ReleaseState,
} from 'shared/lib/types/postgres/manufacturing/types';
import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';
import {
  Item,
  ItemDetail,
  ItemOrder,
  LocationMap,
  PartTag,
  Tag,
  Vendor,
} from '../types';

const LOCATIONS_SETTINGS_ID = 'locations';

type GetPartsOptions = {
  includeUnreleasedParts?: boolean;
  groupByAssemblies?: boolean;
  partIds?: string[];
  includeAllReleasedRevisions?: boolean;
};

/**
 * Internal manufacturing routes.
 *
 * @todo this service references shared postgres types. It should reference shared api types.
 * @todo move public routes to BuildsService
 */
class ManufacturingService {
  static instances = {};

  static getInstance = (teamId: string): ManufacturingService => {
    if (!ManufacturingService.instances[teamId]) {
      ManufacturingService.instances[teamId] = new ManufacturingService(teamId);
    }

    return ManufacturingService.instances[teamId];
  };

  static removeInstance = (teamId: string): void => {
    delete ManufacturingService.instances[teamId];
  };

  teamId: string;
  restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.restUrl = `${API_URL}/teams/${this.teamId}/manufacturing`;
  }

  async getParts(options: GetPartsOptions = {}): Promise<Part[]> {
    const {
      groupByAssemblies,
      includeUnreleasedParts,
      partIds,
      includeAllReleasedRevisions,
    } = options;

    const params = new URLSearchParams();
    if (groupByAssemblies) {
      params.append('groupByAssemblies', 'true');
    }
    if (includeUnreleasedParts) {
      params.append('includeUnreleasedParts', 'true');
    }
    if (partIds) {
      partIds.forEach((partId) => params.append('partId', partId));
    }
    if (includeAllReleasedRevisions) {
      params.append('includeAllReleasedRevisions', 'true');
    }

    const queryParams = params.toString() ? `?${params.toString()}` : '';
    const url = `${this.restUrl}/parts${queryParams}`;

    const response = await superlogin.getHttp().get(url);
    return response.data.parts;
  }

  async getAssemblyParts(): Promise<Part[]> {
    return this.getParts({ groupByAssemblies: true });
  }

  async getPart(
    partId: string,
    options?: {
      revisionId: string | null;
      includeComponentTree?: boolean;
      includeTopLevelParentBOMs?: boolean;
    }
  ): Promise<Part> {
    const params = new URLSearchParams();
    const revisionId = options?.revisionId;
    if (revisionId) {
      params.append('revisionId', revisionId);
    }
    const includeComponentTree = options?.includeComponentTree;
    if (includeComponentTree) {
      params.append('includeComponentTree', 'true');
    }

    const includeTopLevelParentBOMs = options?.includeTopLevelParentBOMs;
    if (includeTopLevelParentBOMs) {
      params.append('includeTopLevelParentBOMs', 'true');
    }
    const partUrl = `${this.restUrl}/parts/${partId}`;
    const url = `${partUrl}?${params.toString()}`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async createParts(parts: Partial<Part>[]): Promise<Part[]> {
    const url = `${this.restUrl}/bulk/parts`;
    const response = await superlogin.getHttp().post(url, parts);
    return response.data;
  }

  async updatePart(partId: string, part: Part): Promise<Part> {
    const url = `${this.restUrl}/parts/${partId}`;
    const response = await superlogin.getHttp().post(url, part);
    return response.data;
  }

  async importCreateParts(parts: Partial<Part>[]): Promise<Part[]> {
    const url = `${this.restUrl}/bulk/import-parts`;
    const response = await superlogin.getHttp().post(url, parts);
    return response.data;
  }

  async importUpdateParts(parts: Partial<Part>[]): Promise<Part[]> {
    const url = `${this.restUrl}/bulk/import-update-parts`;
    const response = await superlogin.getHttp().post(url, parts);
    return response.data;
  }

  async deletePart(partId: string): Promise<void> {
    const url = `${this.restUrl}/parts/${partId}`;
    return superlogin.getHttp().delete(url);
  }

  async listItems(): Promise<Item[]> {
    const url = `${this.restUrl}/items`;
    const response = await superlogin.getHttp().get(url);
    return response.data.items;
  }

  async getItemByPart(partId: string): Promise<Item | undefined> {
    const url = `${this.restUrl}/items`;
    const response = await superlogin.getHttp().get(url);
    return response.data.items.filter((item) => item.part.id === partId)[0];
  }

  async getItemsByPartNo(partNo: string): Promise<Item[]> {
    const url = `${this.restUrl}/items`;
    const response = await superlogin.getHttp().get(url);
    return response.data.items.filter((item) => item.part.part_no === partNo);
  }

  async getItem(
    itemId: string,
    opts: { includeABOMs?: boolean } = {}
  ): Promise<Item> {
    const params = new URLSearchParams();
    if (opts.includeABOMs) {
      params.append('includeABOMs', 'true');
    }
    const queryParams = params.toString() ? `?${params.toString()}` : '';

    const url = `${this.restUrl}/items/${itemId}${queryParams}`;

    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async getRunsLinkedToItem(
    itemId: string
  ): Promise<Array<ItemOccurrenceInRun>> {
    const url = `${this.restUrl}/items/${itemId}/runs`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async getOrdersLinkedToItem(itemId: string): Promise<Array<ItemOrder>> {
    const url = `${this.restUrl}/items/${itemId}/orders`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async addItems(items: Item[]): Promise<Item[]> {
    const url = `${this.restUrl}/bulk/items`;
    const response = await superlogin.getHttp().post(url, items);
    return response.data;
  }

  async updateItem(item: Item): Promise<Item> {
    const url = `${this.restUrl}/items/${item.id}`;
    const response = await superlogin.getHttp().post(url, item);
    return response.data;
  }

  async deleteItem(itemId: string): Promise<undefined> {
    const url = `${this.restUrl}/items/${itemId}`;
    const response = await superlogin.getHttp().delete(url);
    return response.data;
  }

  async getLocations(): Promise<LocationMap> {
    const url = `${this.restUrl}/settings/${LOCATIONS_SETTINGS_ID}`;
    const response = await superlogin.getHttp().get(url);
    return response.data?.locations || {};
  }

  async updateLocations(locations: LocationMap): Promise<Location[]> {
    const url = `${this.restUrl}/settings/${LOCATIONS_SETTINGS_ID}`;
    const setting = { locations };
    const response = await superlogin.getHttp().post(url, setting);
    return response.data.locations;
  }

  async listVendors(): Promise<Vendor[]> {
    const url = `${this.restUrl}/vendors`;
    const response = await superlogin.getHttp().get(url);
    return response.data.vendors;
  }

  async createVendors(vendors: Array<Vendor>): Promise<Array<string>> {
    const url = `${this.restUrl}/vendors`;
    const response = await superlogin.getHttp().post(url, { vendors });
    return response.data.ids;
  }

  async updateVendor(vendorId: string, vendor: Vendor): Promise<Vendor> {
    const url = `${this.restUrl}/vendors/${vendorId}`;
    const response = await superlogin.getHttp().post(url, vendor);
    return response.data;
  }

  async deleteVendor(vendorId: string): Promise<void> {
    const url = `${this.restUrl}/vendors/${vendorId}`;
    return superlogin.getHttp().delete(url);
  }

  async listOrders(): Promise<PurchaseOrder[]> {
    const url = `${this.restUrl}/orders`;
    const response = await superlogin.getHttp().get(url);
    return response.data.orders;
  }

  async getOrder(orderId: string): Promise<PurchaseOrder> {
    const url = `${this.restUrl}/orders/${orderId}`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async createOrder(order: PurchaseOrder): Promise<PurchaseOrder> {
    const url = `${this.restUrl}/orders`;
    const response = await superlogin
      .getHttp()
      // Omit 'state': unused by this endpoint and conflicts with passport-http-bearer-sl
      .post(url, omit(order, ['state']));
    return response.data;
  }

  async updateOrder(
    orderId: string,
    order: PurchaseOrder
  ): Promise<PurchaseOrder> {
    const url = `${this.restUrl}/orders/${orderId}`;
    const response = await superlogin
      .getHttp()
      // Omit 'state': unused by this endpoint and conflicts with passport-http-bearer-sl
      .post(url, omit(order, ['state']));
    return response.data;
  }

  async setOrderState(
    orderId: string,
    state: PurchaseOrderState
  ): Promise<void> {
    const url = `${this.restUrl}/orders/${orderId}/state`;
    return superlogin.getHttp().put(url, { order_state: state });
  }

  async approveOrder(
    orderId: string,
    reviewerGroupId: string,
    reviewerId: string,
    operatorRole: string
  ): Promise<PurchaseOrder> {
    const url = `${this.restUrl}/orders/${orderId}/approvals`;
    const response = await superlogin.getHttp().post(url, {
      reviewer_group_id: reviewerGroupId,
      reviewer_id: reviewerId,
      operator_role: operatorRole,
    });
    return response.data;
  }

  async closeOrder(
    orderId: string,
    items: Array<Item>
  ): Promise<PurchaseOrder> {
    const url = `${this.restUrl}/orders/${orderId}/received-items`;
    const response = await superlogin.getHttp().post(url, items);
    return response.data;
  }

  async deleteOrder(orderId: string): Promise<void> {
    const url = `${this.restUrl}/orders/${orderId}`;
    return superlogin.getHttp().delete(url);
  }

  async listItemsReceivedForOrder(orderId: string): Promise<Array<ItemOrder>> {
    const url = `${this.restUrl}/orders/${orderId}/received-items`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async listPartDetails(): Promise<Array<PartDetail>> {
    const url = `${this.restUrl}/part-details`;
    const response = await superlogin.getHttp().get(url);
    return response.data.part_details;
  }

  async createPartDetail(partDetail: Partial<PartDetail>): Promise<PartDetail> {
    const url = `${this.restUrl}/part-details`;
    const response = await superlogin.getHttp().post(url, partDetail);
    return response.data;
  }

  async updatePartDetail(
    partDetailId: string,
    partDetail: Partial<PartDetail>
  ): Promise<PartDetail> {
    const url = `${this.restUrl}/part-details/${partDetailId}`;
    const response = await superlogin.getHttp().post(url, partDetail);
    return response.data;
  }

  async listTags(): Promise<Array<Tag>> {
    const url = `${this.restUrl}/tags`;
    const response = await superlogin.getHttp().get(url);
    return response.data.tags;
  }

  async createTag(tag: Partial<Tag>): Promise<Tag> {
    const url = `${this.restUrl}/tags`;
    const response = await superlogin.getHttp().post(url, tag);
    return response.data;
  }

  async listTagsForPart(partId: string): Promise<Array<Tag>> {
    const url = `${this.restUrl}/parts/${partId}/tags`;
    const response = await superlogin.getHttp().get(url);
    return response.data.tags;
  }

  async createPartTag(partId: string, tagId: string): Promise<PartTag> {
    const url = `${this.restUrl}/parts/${partId}/tags/${tagId}`;
    const response = await superlogin.getHttp().put(url);
    return response.data.partTag;
  }

  async deletePartTag(partId: string, tagId: string): Promise<void> {
    const url = `${this.restUrl}/parts/${partId}/tags/${tagId}`;
    return superlogin.getHttp().delete(url);
  }

  async listPartTags(): Promise<Array<PartTag>> {
    const url = `${this.restUrl}/part-tags`;
    const response = await superlogin.getHttp().get(url);
    return response.data.part_tags;
  }

  async listItemDetails(): Promise<Array<ItemDetail>> {
    const url = `${this.restUrl}/item-details`;
    const response = await superlogin.getHttp().get(url);
    return response.data.item_details;
  }

  async createItemDetail(itemDetail: Partial<ItemDetail>): Promise<ItemDetail> {
    const url = `${this.restUrl}/item-details`;
    const response = await superlogin.getHttp().post(url, itemDetail);
    return response.data;
  }

  async updateItemDetail(
    itemDetailId: string,
    itemDetail: Partial<ItemDetail>
  ): Promise<ItemDetail> {
    const url = `${this.restUrl}/item-details/${itemDetailId}`;
    const response = await superlogin.getHttp().post(url, itemDetail);
    return response.data;
  }

  async updatePartRevisionState(
    revisionId: string,
    state: ReleaseState
  ): Promise<void> {
    const url = `${this.restUrl}/revisions/${revisionId}/release-state`;
    const body = { release_state: state };
    const response = await superlogin.getHttp().put(url, body);
    return response.data;
  }

  async approvePartRevision(
    revisionId: string,
    reviewerGroupId: string,
    reviewerId: string,
    operatorRole?: string
  ): Promise<void> {
    const url = `${this.restUrl}/revisions/${revisionId}/approvals`;
    const body = {
      reviewer_group_id: reviewerGroupId,
      reviewer_id: reviewerId,
      operator_role: operatorRole,
    };
    const response = await superlogin.getHttp().post(url, body);
    return response.data;
  }

  async deleteRevision(revisionId: string): Promise<void> {
    const url = `${this.restUrl}/revisions/${revisionId}`;
    return superlogin.getHttp().delete(url);
  }
}

export default ManufacturingService;
