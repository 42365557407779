import { AddComment } from 'shared/lib/types/api/manufacturing/comments/requests';
import { UpdateUsageReq } from 'shared/lib/types/api/manufacturing/items/requests';
import { ListItemActivitiesRes } from 'shared/lib/types/api/manufacturing/items/responses';
import {
  CreatePart,
  UpdatePart,
} from 'shared/lib/types/api/manufacturing/parts/requests';
import {
  NextLotNumberRcvRes,
  NextPartNumRcvRes,
  NextSerialRcvRes,
} from 'shared/lib/types/api/manufacturing/parts/responses';
import { WorkOrder } from 'shared/lib/types/api/manufacturing/work_orders/models';
import { CreateWorkOrderReq } from 'shared/lib/types/api/manufacturing/work_orders/requests';
import {
  CreateWorkOrderRes,
  ListWorkOrderActivitiesRes,
  ListWorkOrdersRes,
} from 'shared/lib/types/api/manufacturing/work_orders/responses';
import { PartRevisionComment } from 'shared/lib/types/postgres/manufacturing/comments';
import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';

export type CreatePartsReq = {
  parts: Array<CreatePart>;
};

export type GetPartReq = {
  partId: number;
  revisionId?: number;
};

export type UpdatePartReq = UpdatePart;

export type DeletePartsReq = {
  data: { ids: Array<number> };
};

export type ResetUsageReq = {
  itemId: string;
  usageType: string;
};

/**
 * Public manufacturing routes.
 */
class BuildsService {
  static instances = {};

  static getInstance = (teamId: string): BuildsService => {
    if (!BuildsService.instances[teamId]) {
      BuildsService.instances[teamId] = new BuildsService(teamId);
    }

    return BuildsService.instances[teamId];
  };

  static removeInstance = (teamId: string): void => {
    delete BuildsService.instances[teamId];
  };

  private readonly teamId: string;
  private readonly restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.restUrl = `${API_URL}/teams/${this.teamId}/builds`;
  }

  async nextPartNumber(prefix: string | undefined): Promise<string> {
    const url = `${this.restUrl}/parts/part-numbers${
      prefix ? `?prefix=${prefix}` : ''
    }`;
    const response = await superlogin.getHttp().post<NextPartNumRcvRes>(url);
    return response.data.part_number;
  }

  async nextSerial(partId: string, prefix?: string): Promise<string> {
    const url = `${this.restUrl}/parts/${partId}/serials${
      prefix ? `?prefix=${prefix}` : ''
    }`;
    const response = await superlogin.getHttp().post<NextSerialRcvRes>(url);
    return response.data.serial;
  }

  async nextLotNumber(partId: string, prefix?: string): Promise<string> {
    const url = `${this.restUrl}/parts/${partId}/lot-numbers${
      prefix ? `?prefix=${prefix}` : ''
    }`;
    const response = await superlogin.getHttp().post<NextLotNumberRcvRes>(url);
    return response.data.lot_number;
  }

  async getPartRevisionComments(
    partId: string,
    revisionId: string
  ): Promise<PartRevisionComment[]> {
    const url = `${this.restUrl}/parts/${partId}/comments?revisionId=${revisionId}`;
    const response = await superlogin.getHttp().get(url);
    return response.data.comments;
  }

  async addPartRevisionComment(
    partId: string,
    revisionId: string,
    comment: AddComment
  ): Promise<PartRevisionComment> {
    const url = `${this.restUrl}/parts/${partId}/comments?revisionId=${revisionId}`;
    const response = await superlogin.getHttp().post(url, comment);
    return response.data;
  }

  async listItemActivity(
    itemId: string,
    startBeforeId?: number
  ): Promise<ListItemActivitiesRes> {
    const params = new URLSearchParams();
    if (startBeforeId) {
      params.append('startBeforeId', `${startBeforeId}`);
    }

    const activitiesUrl = `${this.restUrl}/inventory/${itemId}/activity`;
    const query = params.toString() ? `?${params.toString()}` : '';
    const url = `${activitiesUrl}${query}`;

    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async resetUsage({ itemId, usageType }: ResetUsageReq): Promise<void> {
    const url = `${this.restUrl}/inventory/${itemId}/usage`;
    const req: UpdateUsageReq = {
      action: 'reset',
      usage_type: usageType,
    };
    const response = await superlogin.getHttp().post(url, req);
    return response.data;
  }

  async listWorkOrders(): Promise<ListWorkOrdersRes> {
    const url = `${this.restUrl}/work-orders`;
    const response = await superlogin.getHttp().get<ListWorkOrdersRes>(url);
    return response.data;
  }

  async getWorkOrder(workOrderId: string): Promise<WorkOrder> {
    const url = `${this.restUrl}/work-orders/${workOrderId}`;
    const response = await superlogin.getHttp().get<WorkOrder>(url);
    return response.data;
  }

  async createWorkOrder(req: CreateWorkOrderReq): Promise<CreateWorkOrderRes> {
    const url = `${this.restUrl}/work-orders`;
    const response = await superlogin
      .getHttp()
      .post<CreateWorkOrderRes>(url, req);
    return response.data;
  }

  async listWorkOrderActivity(
    workOrderId: string,
    startBeforeId?: number
  ): Promise<ListWorkOrderActivitiesRes> {
    const params = new URLSearchParams();
    if (startBeforeId) {
      params.append('startBeforeId', `${startBeforeId}`);
    }

    const activitiesUrl = `${this.restUrl}/work-orders/${workOrderId}/activity`;
    const query = params.toString() ? `?${params.toString()}` : '';
    const url = `${activitiesUrl}${query}`;

    const response = await superlogin.getHttp().get(url);
    return response.data;
  }
}

export default BuildsService;
