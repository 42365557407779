export const DefaultStepDetailProperties = Object.freeze({
  Timing: 'timing',
  Timer: 'timer',
  Duration: 'duration',
  Location: 'location',
  Channel: 'channel',
});

export const DefaultStepDetailDefinitions = {
  [DefaultStepDetailProperties.Timing]: {
    id: DefaultStepDetailProperties.Timing,
    title: 'Timing',
    type: 'text',
    icon: 'clock',
  },
  [DefaultStepDetailProperties.Timer]: {
    id: DefaultStepDetailProperties.Timer,
    title: 'Timer',
    type: 'timer',
    icon: 'hourglass-half',
  },
  [DefaultStepDetailProperties.Duration]: {
    id: DefaultStepDetailProperties.Duration,
    title: 'Duration',
    type: 'duration',
    icon: 'stopwatch',
    placeholder: 'HH:MM:SS',
  },
  [DefaultStepDetailProperties.Location]: {
    id: DefaultStepDetailProperties.Location,
    title: 'Location',
    type: 'text',
    icon: 'map-marker-alt',
  },
  [DefaultStepDetailProperties.Channel]: {
    id: DefaultStepDetailProperties.Channel,
    title: 'Channel',
    type: 'text',
    icon: 'satellite-dish',
  },
};

export const StepDetailType = Object.freeze({
  Text: 'text',
  OperatorSelect: 'operatorSelect',
  Duration: 'duration',
  Timer: 'timer',
});

export const DefaultValuesByStepDetailType = Object.freeze({
  [StepDetailType.Text]: '',
  [StepDetailType.OperatorSelect]: [],
  [StepDetailType.Duration]: {
    started_at: '',
    duration: '',
  },
  [StepDetailType.Timer]: {
    started_at: '',
    time_left: '',
    completed: false,
    time_remaining: '',
  },
});
