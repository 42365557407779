import React, { ComponentProps } from 'react';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface FieldSetInputWithIconProps extends ComponentProps<'input'> {
  className: string;
  icon: IconProp;
  errors?: string;
  isDisabled?: boolean;
}

const FieldSetInputWithIcon = React.memo(
  ({ className, icon, errors, isDisabled = false, ...props }: FieldSetInputWithIconProps) => (
    <div className="grow w-full">
      <div className="grow relative">
        <div className="absolute top-2 left-3 text-gray-400">
          <FontAwesomeIcon icon={icon} />
        </div>
        <Field
          className={`${className ? className : ''} pl-10 w-full ${isDisabled ? 'bg-gray-200' : ''}`}
          disabled={isDisabled}
          {...props}
        />
      </div>
      {errors && <div className="text-red-700">{errors}</div>}
    </div>
  )
);

export default FieldSetInputWithIcon;
