import { useRunContext } from '../contexts/RunContext';
import { useProcedureContext } from '../contexts/ProcedureContext';
import { useReviewContext } from '../contexts/ReviewContext';
import {
  Section,
  SectionDiffElement,
  Variable,
  VariableDiffElement,
  SourceConditionalsMap,
  Step,
} from 'shared/lib/types/views/procedures';
import { Summary } from '../lib/procedureUtil';

import { ExpressionReferenceTargetBlock } from 'shared/lib/types/expressions';

export type GetReferencedContentContext = (
  referencedContentId: string,
  version?: 'old' | 'new',
  references?: {
    referenced_section_id: string;
    referenced_step_id: string;
  }
) => null | {
  referencedContent: ExpressionReferenceTargetBlock;
  referencedContentIndex?: number;
  isVariable: boolean;
  isVariableRecorded: boolean;
  referencedFromStepKey?: string;
  referencedFromSectionKey?: string;
  sectionRepeatKey?: string;
  stepRepeatKey?: string;
  stepRecordedState?: 'completed' | 'failed' | 'skipped';
  stepEndedTimestamp?: string;
  referencedFromSectionId?: string;
  referencedFromStepId?: string;
  referencedFromStep?: Step;
  subStepKeyMap?: { [contentId: string]: string };
};

type UseProcedureAdapterReturns = {
  stepIdsToLabelsMap: { [stepId: string]: string };
  oldStepIdsToLabelsMap?: { [stepId: string]: string };
  sourceStepConditionalsMap: SourceConditionalsMap;
  oldSourceStepConditionalsMap?: SourceConditionalsMap;
  removedSourceStepConditionalsMap?: SourceConditionalsMap;
  getReferencedContentContext: GetReferencedContentContext;
  getAllSections: (() => Array<Section>) | ((version?: 'old' | 'new') => Array<SectionDiffElement>);
  getAllVariables: (() => Array<Variable>) | ((version?: 'old' | 'new') => Array<VariableDiffElement>);
  getSectionSummary: (sectionId: string, version?: 'old' | 'new') => Summary | null;
  getStepSummary: (stepId: string, sectionId: string, version?: 'old' | 'new') => Summary | null;
};

/**
 * Hook to handle cases in which a component can exist in multiple procedure contexts, such as Run, Procedure, Draft, Review, and Diff
 */
const useProcedureAdapter = (): UseProcedureAdapterReturns => {
  const {
    isRun,
    isPreviewMode,
    stepIdsToLabelsMap: runLabelMap,
    sourceStepConditionalsMap: runSourceStepConditionalsMap,
    getReferencedContentContext: getReferencedContentContextForRun,
  } = useRunContext();
  const {
    isDiffShown,
    isReview,
    stepIdsToLabelsMap: reviewLabelMap,
    oldStepIdsToLabelsMap: oldReviewLabelMap,
    sourceStepConditionalsMap: reviewSourceStepConditionalsMap,
    oldSourceStepConditionalsMap: oldReviewSourceStepConditionalsMap,
    removedSourceStepConditionalsMap: reviewRemovedSourceStepConditionalsMap,
    getReferencedContentContext: getReferencedContentContextForDiff,
    getAllSections: getAllSectionsForDiff,
    getAllVariables: getAllVariablesForDiff,
    getSectionSummary: getSectionSummaryForDiff,
    getStepSummary: getStepSummaryForDiff,
  } = useReviewContext();
  const {
    stepIdsToLabelsMap: procedureLabelMap,
    sourceStepConditionalsMap: procedureSourceStepConditionalsMap,
    getReferencedContentContext: getReferencedContentContextForProcedure,
    getAllSections: getAllSectionsForProcedure,
    getAllVariables: getAllVariablesForProcedure,
    getSectionSummary: getSectionSummaryForProcedure,
    getStepSummary: getStepSummaryForProcedure,
  } = useProcedureContext();

  if (isRun || isPreviewMode) {
    return {
      stepIdsToLabelsMap: runLabelMap,
      sourceStepConditionalsMap: runSourceStepConditionalsMap,
      getReferencedContentContext: getReferencedContentContextForRun,
      getAllSections: getAllSectionsForProcedure,
      getAllVariables: getAllVariablesForProcedure,
      getSectionSummary: getSectionSummaryForProcedure,
      getStepSummary: getStepSummaryForProcedure,
    };
  }

  if (isDiffShown) {
    return {
      stepIdsToLabelsMap: reviewLabelMap,
      oldStepIdsToLabelsMap: oldReviewLabelMap,
      sourceStepConditionalsMap: reviewSourceStepConditionalsMap,
      oldSourceStepConditionalsMap: oldReviewSourceStepConditionalsMap,
      removedSourceStepConditionalsMap: reviewRemovedSourceStepConditionalsMap,
      getReferencedContentContext: getReferencedContentContextForDiff,
      getAllSections: getAllSectionsForDiff,
      getAllVariables: getAllVariablesForDiff,
      getSectionSummary: getSectionSummaryForDiff,
      getStepSummary: getStepSummaryForDiff,
    };
  }

  if (isReview) {
    return {
      stepIdsToLabelsMap: reviewLabelMap,
      sourceStepConditionalsMap: reviewSourceStepConditionalsMap,
      getReferencedContentContext: getReferencedContentContextForProcedure,
      getAllSections: getAllSectionsForProcedure,
      getAllVariables: getAllVariablesForProcedure,
      getSectionSummary: getSectionSummaryForProcedure,
      getStepSummary: getStepSummaryForProcedure,
    };
  }

  // Default to ProcedureContext
  return {
    stepIdsToLabelsMap: procedureLabelMap,
    sourceStepConditionalsMap: procedureSourceStepConditionalsMap,
    getReferencedContentContext: getReferencedContentContextForProcedure,
    getAllSections: getAllSectionsForProcedure,
    getAllVariables: getAllVariablesForProcedure,
    getSectionSummary: getSectionSummaryForProcedure,
    getStepSummary: getStepSummaryForProcedure,
  };
};

export default useProcedureAdapter;
