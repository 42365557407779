import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikHelpers, FormikValues } from 'formik';
import _ from 'lodash';
import { useMemo } from 'react';
import { ComponentPart, Part } from 'shared/lib/types/postgres/manufacturing/types';
import { PartList } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import idUtil from '../../lib/idUtil';
import usePartBlockHelpers from '../hooks/usePartBlockHelpers';
import useParts from '../hooks/useParts';
import inventoryUtil from '../lib/inventoryUtil';
import { asComponentPart, getPartRevisionId, isPartRestricted } from '../lib/parts';
import FieldInputComponent from './FieldInputComponent';
import PartAndRevisionPusher from './PartAndRevisionPusher';
import PartAndRevisionSelect from './PartAndRevisionSelect';

type PartListFieldSetProps = {
  content: PartList;
  contentErrors?: { [key: string]: string };
  projectId?: string;
  path?: string;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  onPartListChanged: (partList: PartList) => void;
  onPartListRemoved: () => void;
};

const PartListFieldSet = ({
  content,
  contentErrors,
  projectId,
  path,
  setFieldValue,
  onPartListChanged,
  onPartListRemoved,
}: PartListFieldSetProps) => {
  const partIdsInBlock = useMemo(() => inventoryUtil.collectPartIdsInBlock(content), [content]);
  const { getKitItems } = usePartBlockHelpers({});

  const { getPart, getPartByRevisionId } = useParts({
    partIds: partIdsInBlock,
    includeAllReleasedRevisions: true,
  });
  const { currentTeamId } = useDatabaseServices();

  const onAddItem = (selectedPart: Part) => {
    const component = asComponentPart(selectedPart, 1);
    const value: PartList = {
      ...content,
      items: [...(content.items ?? []), { ...component, id: idUtil.generateUuidEquivalentId() }],
    };
    setFieldValue(path ? path : '', value);
  };

  const onItemChanged = (values: ComponentPart) => {
    const updated = _.cloneDeep(content);
    for (const item of updated.items) {
      if (item.id === values.id) {
        item.amount = values.amount;
      }
    }
    setFieldValue(path ? path : '', updated);
  };

  const onPartChanged = (part: Part | null) => {
    // Update part built and parts list.
    const updated: PartList = {
      id: content.id,
      type: 'part_list',
      part,
      part_id: part?.id ?? null,
      items: part ? getKitItems(part) : [],
    };

    onPartListChanged(updated);
  };

  const onItemRemoved = (component: ComponentPart) => {
    const updated = _.cloneDeep(content);
    updated.items = updated.items.filter((_component) => _component.id !== component.id);
    setFieldValue(path ? path : '', updated);
  };

  const bomPartRevisionId = content.part ? getPartRevisionId(content.part) : undefined;
  const bomPart = getPartByRevisionId(bomPartRevisionId ?? '') ?? content.part;

  return (
    <div>
      <div className="flex text-lg h-9 my-2 items-center">
        <span className="font-semibold">Bill of Materials</span>
      </div>
      <div className="flex flex-col grow group mx-12">
        <div className="flex">
          <div className="flex flex-col items-start">
            <PartAndRevisionSelect
              selectedPart={bomPart}
              projectId={projectId}
              onSelect={onPartChanged}
              partSelectLabel="Part to Build"
              canClearPart={false}
            />
            {contentErrors?.part_id && <div className="text-red-700 text-sm">{contentErrors.part_id}</div>}
          </div>
          <div className="w-10 h-full flex flex-col justify-start opacity-0 group-hover:opacity-100">
            {/* Remove content block */}
            <button type="button" title="Remove Part Build" tabIndex={-1} onClick={onPartListRemoved}>
              <FontAwesomeIcon
                icon="times-circle"
                className="self-center text-gray-400 hover:text-gray-500"
              ></FontAwesomeIcon>
            </button>
          </div>
        </div>

        {/* Part components */}
        {bomPart && (
          <table className="table-auto w-full">
            <thead>
              <tr>
                <td>
                  <div className="py-1">
                    <label htmlFor="components" className="field-title">
                      Component Materials
                    </label>
                  </div>
                </td>
                <td>
                  <div className="px-2 py-1">
                    <span className="field-title">Quantity</span>
                  </div>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {!content.items.length && (
                <tr className="h-10 text-sm text-gray-400 bg-white border-b items-center text-center">
                  <td colSpan={4}>No Parts Added</td>
                </tr>
              )}
              {content.items.map((component, index) => (
                <FieldInputComponent
                  key={index}
                  component={component}
                  part={getPartByRevisionId(component.revision_id ?? '') ?? getPart(component.part_id)}
                  teamId={currentTeamId}
                  onChanged={onItemChanged}
                  onRemoved={onItemRemoved}
                  partRestricted={isPartRestricted(getPart(component.part_id))}
                />
              ))}
              <tr>
                <td colSpan={3}>
                  <PartAndRevisionPusher
                    onPush={onAddItem}
                    shouldDisablePartSelect={(part: Part | ComponentPart) => part.id === content.part_id}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default PartListFieldSet;
