import _ from 'lodash';
import { DateTime, Interval } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import labels from 'shared/lib/labelUtil';
import { CouchLikeOperation, CreateOperationRequest } from 'shared/lib/types/operations';
import { Release, Run } from 'shared/lib/types/views/procedures';
import { FrontendEvent as Event, Swimlane } from 'shared/schedule/types/event';
import LoadingScreen from '../components/LoadingScreen';
import { MenuContextAction } from '../components/MenuContext';
import OperationEventList from '../components/Operations/OperationEventList';
import { useAuth } from '../contexts/AuthContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import { DatabaseServices } from '../contexts/proceduresSlice';
import apm from '../lib/apm';
import { PERM } from '../lib/auth';
import OperationUtil from '../lib/operationUtil';
import { operationViewPath, operationsDashboardPath } from '../lib/pathUtil';
import Calendar from '../schedule/components/Calendar';
import Gantt from '../schedule/components/Gantt';
import ViewModeSelect from '../schedule/components/ViewModeSelect';
import useCreateEventModal from '../schedule/hooks/useCreateEventModal';
import OperationToolbar from '../components/Operations/OperationToolbar';
import SidebarLayout from '../elements/SidebarLayout';
import EntityProgressBar, { ProgressStep } from '../components/EntityDetail/EntityProgressBar';
import Field, { FieldDefinition } from '../components/EntityDetail/Field';
import RunStatusLabel from '../components/RunStatusLabel';
import UserId from '../elements/UserId';
import AssigneeSelector from '../components/Selectors/AssigneeSelector';
import pluralize from 'pluralize';
import FlashMessage from '../components/FlashMessage';
import Button, { BUTTON_TYPES } from '../components/Button';
import DuplicateOperationModal from '../components/Operations/OperationFormModal/DuplicateOperationModal';
import EditOperationModal from '../components/Operations/OperationFormModal/EditOperationModal';
import { OperationFormValues, SaveResult } from '../components/Operations/OperationFormModal/types';
import ActivityDisplay from '../components/Operations/OperationActivity/Display';
import { ListOperationActivitiesRes } from 'shared/lib/types/api/operations/requests';
import useRealtimeUpdates, { useRealtimeUpdatesProps } from '../hooks/useRealtimeUpdates';
import { RealtimeData } from 'shared/lib/types/realtimeUpdatesTypes';
import { EventsProvider } from '../schedule/contexts/EventsContext';
import { getAllLinkedProcedureIds } from '../components/Operations/lib/operationUtil';

export const END_OPERATION_MESSAGE = 'Are you sure you want to end this operation?';
export const CANNOT_START_EMPTY_OPERATION_MESSAGE = 'Please add at least one procedure to start this operation.';
export const CANNOT_END_OPERATION_WITH_RUNNING_PROCEDURES_MESSAGE =
  'This operation has unstarted or running procedures and cannot be ended.';

const DEFAULT_PERIOD_BUFFER = { days: 1 };
const PERIOD_BUFFER_PERCENT = 0.1;
const INITIAL_TIME_SCALE = 0.00001;

const INCLUDE_DELETED_EVENTS = true;

const PROGRESS_STEPS: Array<ProgressStep> = [
  { id: 'planning', percent: 0 },
  { id: 'running', percent: 50 },
  { id: 'ended', percent: 100 },
];
const END_RUNS_MESSAGE = 'The selected runs will be marked as "Completed". Are you sure you want to proceed?';

export interface EventMap {
  [eventId: string]: Event;
}

const OperationDetail = () => {
  const { auth } = useAuth();
  const history = useHistory();

  const { mixpanel } = useMixpanel();
  const mixpanelTrack = useCallback(
    (trackingKey: string, properties?: object | undefined) => {
      if (mixpanel) {
        mixpanel.track(trackingKey, properties);
      }
    },
    [mixpanel]
  );

  const { id } = useParams<{ id: string }>();
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const [viewMode, setViewMode] = useState<string>('list');

  const [operation, setOperation] = useState<CouchLikeOperation | undefined>();
  const [procedures, setProcedures] = useState<Array<Release>>([]);
  const [runs, setRuns] = useState<Array<Run>>([]);
  const [loadingRows, setLoadingRows] = useState(true);

  const [showOperationFormModal, setShowOperationFormModal] = useState<{
    isVisible: boolean;
    type: 'edit' | 'duplicate';
  }>({ isVisible: false, type: 'edit' });

  const [endOperationDisabled, setEndOperationDisabled] = useState(true);

  const [events, setEvents] = useState<Array<Event> | undefined>();
  const [deletedEvents, setDeletedEvents] = useState<Array<Event> | undefined>();
  const [selectedRows, setSelectedRows] = useState<Set<Event['id']>>(new Set());
  const [swimlanes, setSwimlanes] = useState<Array<Swimlane> | undefined>();
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [activityLog, setActivityLog] = useState<ListOperationActivitiesRes>();

  const { displayCreateEventModal, CreateEventModal, eventSaved } = useCreateEventModal();

  const eventMap: EventMap = useMemo(
    () => Object.fromEntries((events || []).map((event) => [event.id, event])),
    [events]
  );

  useEffect(() => {
    services.operation
      .getOperation(decodeURIComponent(id))
      .then(setOperation)
      .catch((err) => apm.captureError(err));
  }, [id, services.operation]);

  const getInitialOperation = useCallback(async () => {
    return operation ? [operation] : [];
  }, [operation]);

  const { realtimeData: realtimeOperation } = useRealtimeUpdates<RealtimeData & CouchLikeOperation>({
    entityType: 'operation',
    entityId: operation?.id,
    initialGetter: getInitialOperation,
    singleEntity: true,
  } as useRealtimeUpdatesProps);

  const loadEvents = useCallback(() => {
    Promise.all([
      services.events.getOperationEvents(decodeURIComponent(id), INCLUDE_DELETED_EVENTS),
      services.swimlanes.getSwimlanes(),
    ])
      .then(([newEvents, newSwimlanes]) => {
        const [validEvents, deletedEvents] = _.partition(newEvents, (e) => !e.deleted);
        setEvents(validEvents);
        setDeletedEvents(deletedEvents);
        setSwimlanes(newSwimlanes);
      })
      .catch(() => {
        setError('Error loading schedule - please refresh and try again.');
      });
  }, [id, services.events, services.swimlanes]);

  useEffect(() => {
    if (!realtimeOperation) {
      return;
    }
    setOperation(realtimeOperation as CouchLikeOperation);
    loadEvents();
  }, [realtimeOperation, loadEvents]);

  useEffect(() => {
    loadEvents();
  }, [services.events, services.swimlanes, id, loadEvents, eventSaved]);

  const operationPeriod: Interval = useMemo(() => {
    const scheduledEvents = events?.filter((e) => e.start) ?? [];
    if (scheduledEvents.length === 0) {
      return Interval.fromDateTimes(
        DateTime.utc().minus(DEFAULT_PERIOD_BUFFER),
        DateTime.utc().plus(DEFAULT_PERIOD_BUFFER)
      );
    }
    const firstStart = DateTime.min(...scheduledEvents.map((e) => e.start));
    let lastEnd = DateTime.max(...scheduledEvents.map((e) => e.end || e.start));
    if (operation && operation.state === 'running') {
      lastEnd = DateTime.max(lastEnd, DateTime.utc());
    }
    const durationMillis = Interval.fromDateTimes(firstStart, lastEnd).toDuration().toMillis();
    const bufferMillis = durationMillis * PERIOD_BUFFER_PERCENT;
    return Interval.fromDateTimes(firstStart.minus(bufferMillis), lastEnd.plus(bufferMillis));
  }, [events, operation]);

  useEffect(() => {
    if (!operation || !operation.key) {
      return;
    }

    const procedureIds = getAllLinkedProcedureIds(events || []);
    const proceduresPromise =
      procedureIds.length === 0
        ? Promise.resolve({ procedures: [] })
        : services.procedures.bulkGetProcedures(procedureIds);
    const runsPromise = services.runs.getRunsByOperation(operation.key);
    Promise.all([proceduresPromise, runsPromise])
      .then(([{ procedures }, runs]) => {
        setProcedures(procedures as Array<Release>);
        setRuns(runs);
        setLoadingRows(false);
      })
      .catch((err) => apm.captureError(err));
  }, [operation, services.procedures, services.runs, events]);

  const refreshRuns = useCallback(() => {
    if (!operation || !operation.key) {
      return;
    }

    if (operation.state === 'planning') {
      return;
    }

    services.runs
      .getRunsByOperation(operation.key)
      .then((runs) => setRuns(runs))
      .catch((err) => apm.captureError(err));
  }, [operation, services.runs]);

  useEffect(() => {
    setProcedures([]);
    setRuns([]);
  }, [id]);

  useEffect(() => {
    if (!operation || !operation.id) {
      return;
    }
    services.operation
      .listOperationActivity(operation.id)
      .then((activityLogForOperation) => setActivityLog(activityLogForOperation))
      .catch((err) => apm.captureError(err));
  }, [services.operation, operation]);

  const loadOlderActivity = useCallback(async () => {
    if (!operation || !operation.id) {
      return;
    }
    const startBeforeId = activityLog?.activities[activityLog.activities.length - 1].id;
    services.operation
      .listOperationActivity(operation.id, startBeforeId ? parseInt(startBeforeId) : undefined)
      .then((olderLog) => {
        const updatedActivities = [...(activityLog?.activities ?? []), ...olderLog.activities];
        setActivityLog({ has_older: olderLog.has_older, activities: updatedActivities });
      })
      .catch((err) => apm.captureError(err));
  }, [activityLog, services.operation, operation]);

  useEffect(() => {
    if (!operation || !operation.key) {
      return;
    }

    const observer = services.runs.onRunsByOperationChanged(operation.key, refreshRuns);

    return () => {
      if (!observer) {
        return;
      }
      observer.cancel();
    };
  }, [services.runs, operation, services.procedures, refreshRuns]);

  const operationUtil = useMemo(() => {
    return new OperationUtil(operation?.procedures || [], runs);
  }, [operation?.procedures, runs]);

  useEffect(() => {
    setEndOperationDisabled(
      operationUtil.hasUnstartedProcedures() || runs.filter((run) => run.state !== 'completed').length > 0
    );
  }, [operationUtil, runs]);

  const duplicateOperation = useCallback(
    (values: OperationFormValues): Promise<SaveResult> => {
      if (!operation) {
        return Promise.resolve({
          success: false,
        });
      }

      const duplicateOperation: CreateOperationRequest = {
        name: values.name.trim(),
        state: 'planning',
        description: values.description?.trim(),
        procedures: operationUtil.getAllProcedures(),
        events: events
          ?.filter((event) => event.is_root_event)
          .map((event) => ({
            ..._.omit(event, 'run_id'),
            status: 'planning',
          })),
      };

      return services.operation
        .saveOperation(duplicateOperation)
        .then(() => mixpanel?.track('Duplicate Operation'))
        .then(() => {
          const key = labels.getLabelKey(duplicateOperation.name);
          history.push(operationViewPath(currentTeamId, key));
        })
        .then(() => ({ success: true }))
        .catch((err) => ({
          success: false,
          message: err.message,
        }))
        .finally(() => setShowOperationFormModal({ isVisible: false, type: 'duplicate' }));
    },
    [currentTeamId, events, history, mixpanel, operation, operationUtil, services.operation]
  );

  const updateOperation = useCallback(
    async (values: OperationFormValues) => {
      try {
        await services.operation.updateOperation(values);
        setShowOperationFormModal({ isVisible: false, type: 'edit' });
        setSuccessMessage('Description updated');
      } catch (err) {
        apm.captureError(err);
        return { success: false, message: err.message };
      }
      return { success: true };
    },
    [services.operation]
  );

  const startOperation = useCallback(async () => {
    if (!operation) {
      return Promise.resolve();
    }

    try {
      const results = await services.operation.startOperation(operation.name);
      operation.state = 'running';
      mixpanelTrack('Start Operation', { Source: 'Operation Detail' });
      return results;
    } catch {
      // ignore
    }
  }, [mixpanelTrack, operation, services]);

  const endOperation = useCallback(async () => {
    if (!window.confirm(END_OPERATION_MESSAGE) || !operation) {
      return Promise.resolve();
    }

    try {
      const results = await services.operation.endOperation(operation.name);
      mixpanelTrack('End Operation', { Source: 'Operation Detail' });
      history.push(operationsDashboardPath(currentTeamId));
      return results;
    } catch {
      // ignore
    }
  }, [history, mixpanelTrack, operation, services, currentTeamId]);

  const endRuns = useCallback(async () => {
    if (!window.confirm(END_RUNS_MESSAGE)) {
      return;
    }

    const runIds = Array.from(selectedRows)
      .map((eventId) => eventMap[eventId].run_id)
      .filter((runId): runId is string => !!runId);
    const results = await Promise.allSettled(
      runIds.map((runId) => {
        return services.runs.endRunExternal(runId);
      })
    );
    const failures = results.filter((result) => result.status === 'rejected');
    if (failures.length > 0) {
      failures.forEach((failure) => {
        if (failure.status === 'rejected') {
          apm.captureError(failure.reason);
        }
      });
      setError(`Failed to end ${failures.length} ${pluralize('run', failures.length)}`);
    }

    const successCount = results.length - failures.length;
    if (successCount > 0) {
      setSuccessMessage(`Successfully ended ${successCount} ${pluralize('run', successCount)}`);
    }

    refreshRuns();
    setSelectedRows(new Set());
  }, [selectedRows, services.runs, refreshRuns, setSuccessMessage, setError, eventMap]);

  const menuActions = useMemo(() => {
    const actions: Array<MenuContextAction> = [];

    if (operation?.state === 'planning') {
      actions.push({
        type: 'label',
        label: 'Start Operation',
        data: {
          icon: 'play',
          title: procedures.length === 0 ? CANNOT_START_EMPTY_OPERATION_MESSAGE : 'Start Operation',
          onClick: startOperation,
          disabled: procedures.length === 0,
        },
      });
    }

    if (operation?.state === 'running') {
      actions.push({
        type: 'label',
        label: 'End Operation',
        data: {
          icon: 'stop',
          title: endOperationDisabled ? CANNOT_END_OPERATION_WITH_RUNNING_PROCEDURES_MESSAGE : 'End Operation',
          onClick: endOperation,
          disabled: endOperationDisabled,
        },
      });
    }

    if (operation?.state !== 'ended') {
      actions.push({
        type: 'label',
        label: 'Edit Operation',
        data: {
          icon: 'pen',
          title: 'Edit Operation',
          onClick: () => setShowOperationFormModal({ isVisible: true, type: 'edit' }),
        },
      });
    }

    actions.push({
      type: 'label',
      label: 'Duplicate Operation',
      data: {
        icon: 'copy',
        title: 'Duplicate Operation',
        onClick: () => setShowOperationFormModal({ isVisible: true, type: 'duplicate' }),
        disabled: showOperationFormModal.isVisible,
      },
    });

    return actions;
  }, [
    operation?.state,
    procedures.length,
    startOperation,
    endOperationDisabled,
    endOperation,
    setShowOperationFormModal,
    showOperationFormModal.isVisible,
  ]);

  const updateAssignee = useCallback(
    async (userId: string | null) => {
      if (!operation) {
        return;
      }
      try {
        await services.operation.updateOperation({
          name: operation.name,
          assigneeUserId: userId,
        });

        setSuccessMessage('Assignee updated');
      } catch (err) {
        apm.captureError(err);
        return;
      }
    },
    [services.operation, operation]
  );

  const fields: Array<FieldDefinition<CouchLikeOperation>> = [
    {
      label: 'Status',
      formatter: (operation) => <RunStatusLabel statusText={operation.state || 'planning'} />,
    },
    {
      label: 'Assignee',
      formatter: (operation) => (
        <div className="flex flex-row items-center">
          {!operation.assignee_user_id && <div className="text-sm truncate italic text-gray-400">Unassigned</div>}
          {operation.assignee_user_id && <UserId userId={operation.assignee_user_id} />}
        </div>
      ),
      editor: (operation) => (
        <div className="-mt-0.5">
          <AssigneeSelector
            assignee={operation.assignee_user_id ?? undefined}
            canBeUnassigned={true}
            onChange={(userId) => updateAssignee(userId)}
          />
        </div>
      ),
    },
    {
      label: 'Created By',
      formatter: (operation) => (
        <div className="-mt-1">
          <UserId userId={operation.created_by || ''} timestamp={operation.created_at || ''} />
        </div>
      ),
    },
  ];

  const addEvent = useCallback(async () => {
    if (!operation) {
      return;
    }
    mixpanelTrack('Add Event', { Source: 'Operation Detail' });
    displayCreateEventModal({ operation });
  }, [mixpanelTrack, operation, displayCreateEventModal]);

  if (!operation || loadingRows || !events || !swimlanes) {
    return <LoadingScreen />;
  }

  const mainContent = () => {
    if (error && viewMode !== 'list') {
      return (
        <div className="flex h-full items-center justify-center">
          <span className="text-red-700">{error}</span>
        </div>
      );
    }
    switch (viewMode) {
      case 'gantt':
        return (
          <Gantt period={operationPeriod} events={events} swimlanes={swimlanes} initialTimeScale={INITIAL_TIME_SCALE} />
        );
      case 'list':
        return (
          <OperationEventList
            operation={operation}
            procedures={procedures}
            setProcedures={setProcedures}
            runs={runs}
            eventMap={eventMap}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        );
      case 'cal':
        return <Calendar period={operationPeriod} events={events} />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Planning - Operation Details</title>
      </Helmet>
      <OperationToolbar
        operation={operation}
        addEvent={addEvent}
        hasEditPermission={auth.hasPermission(PERM.RUNS_EDIT)}
        menuActions={menuActions}
      />
      <CreateEventModal />
      <FlashMessage message={successMessage} messageUpdater={setSuccessMessage} topOffset={5} />
      <FlashMessage message={error} messageUpdater={setError} type="warning" topOffset={5} />
      <SidebarLayout paddingTop="pt-10">
        <SidebarLayout.Sidebar>
          <div style={{ height: `calc(100% - 10px)` }} className="flex flex-col justify-between overflow-hidden">
            <div className="flex flex-col overflow-hidden min-h-[35%]">
              <div className="w-full pl-6 pr-8 pt-4 pb-6">
                <EntityProgressBar steps={PROGRESS_STEPS} currentStepId={operation.state} />
              </div>
              <div className="border-t w-full border-gray-300 my-2" />
              <div className="flex flex-row items-center justify-between mb-2 h-8">
                <strong className="text-lg font-bold">Details</strong>
              </div>
              <div
                role="region"
                aria-label="Operation Details"
                className="flex flex-col grow overflow-y-auto w-full py-2 gap-y-4"
              >
                {fields.map((field) => (
                  <Field
                    visible={field.visible}
                    fullWidth={field.fullWidth}
                    key={field.label}
                    label={field.label}
                    formatter={field.formatter(operation)}
                    isEditing={auth.hasPermission(PERM.RUNS_EDIT) && operation.state !== 'ended'}
                    editor={field.editor ? field.editor(operation) : field.formatter(operation)}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col overflow-hidden min-h-[65%]">
              <div className="border-t w-full border-gray-300"></div>
              <ActivityDisplay
                activities={activityLog?.activities ?? []}
                hasOlder={activityLog?.has_older ?? false}
                onLoadOlder={loadOlderActivity}
                eventMap={eventMap}
                deletedEvents={deletedEvents || []}
              />
            </div>
          </div>
        </SidebarLayout.Sidebar>
        <SidebarLayout.Content>
          <div className="flex flex-col px-5 py-6 w-full h-full">
            {showOperationFormModal.isVisible && showOperationFormModal.type === 'duplicate' && (
              <DuplicateOperationModal
                operation={operation}
                onSave={duplicateOperation}
                onCancel={() => setShowOperationFormModal({ isVisible: false, type: 'edit' })}
              />
            )}
            {showOperationFormModal.isVisible && showOperationFormModal.type === 'edit' && (
              <EditOperationModal
                operation={operation}
                onSave={updateOperation}
                onCancel={() => setShowOperationFormModal({ isVisible: false, type: 'edit' })}
              />
            )}

            <div className="flex flex-col w-full">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row space-x-2 items-center">
                  <ViewModeSelect viewMode={viewMode} setViewMode={setViewMode} unscheduledEnabled={false} />
                </div>
              </div>
              {operation.description && (
                <div className="text-gray-500 mt-3 mb-2 mx-1 line-clamp-3">{operation.description}</div>
              )}
              {!operation.description && <div className="my-2" />}
            </div>
            {viewMode === 'list' && operation.state === 'running' && (
              <div className="flex w-full">
                <div className="divide-x">
                  <span className="text-sm text-gray-400 w-42 mr-2 ml-1">
                    {selectedRows.size} {pluralize('event', selectedRows.size)} selected
                  </span>
                  <span className="w-28 text-center">
                    <Button
                      isDisabled={!auth.hasPermission(PERM.RUNS_EDIT) || selectedRows.size === 0}
                      type={BUTTON_TYPES.TERTIARY}
                      onClick={endRuns}
                      leadingIcon="stop"
                      title="End Runs"
                    >
                      End {pluralize('Run', selectedRows.size)}
                    </Button>
                  </span>
                </div>
              </div>
            )}
            <EventsProvider events={events}>{mainContent()}</EventsProvider>
          </div>
        </SidebarLayout.Content>
      </SidebarLayout>
    </>
  );
};

export default OperationDetail;
