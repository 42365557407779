import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { isEmptyValue } from 'shared/lib/text';
import { PartAutoNumbering } from 'shared/lib/types/couch/settings';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { PartBuildRecordedItem as RecordedItem } from 'shared/lib/types/views/procedures';
import apm from '../../lib/apm';
import { StringSelectOption as SelectOption } from '../../lib/formik';
import { isPartRestricted } from '../lib/parts';
import { Location } from '../types';
import CheckedInItemLink from './CheckedInItemLink';
import ComponentPartLabel from './ComponentPartLabel';
import LocationSelect from './LocationSelect';
import FullNumberInput from './Numbering/FullNumberInput';
import { Item } from '../lib/inventoryUtil';
import ItemOverlay from './ItemOverlay';
import ItemQuantityInput from './ItemQuantityInput';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';

const showItemLink = (recorded?: RecordedItem): boolean => !!recorded?.item_id;

type FieldInputBuildItemProps = {
  part: Part | undefined;
  item: RecordedItem;
  recorded?: RecordedItem;
  isEnabled: boolean;
  autoNumbering?: PartAutoNumbering;
  onAddLotNumber?: (itemId?: string, prefix?: string) => Promise<void>;
  onClearLotNumber?: (itemId?: string) => Promise<void>;
  onRecordValuesChanged: (values: RecordedItem) => void;
  onRecordErrorsChanged: (errorObj: { [key: string]: string }) => void;
  teamId: string;
  canRemoveItem: boolean;
  onRemoveItem?: () => void;
  checkedOutItems?: Item[];
};

const FieldInputBuildItem = ({
  part,
  item,
  recorded,
  isEnabled,
  autoNumbering,
  onAddLotNumber,
  onClearLotNumber,
  onRecordValuesChanged,
  onRecordErrorsChanged,
  teamId,
  canRemoveItem,
  onRemoveItem,
  checkedOutItems,
}: FieldInputBuildItemProps) => {
  const prefix = useMemo(() => recorded?.prefix ?? item.prefix ?? '', [item.prefix, recorded?.prefix]);
  /**
   * This must be string (blank for unset).
   * 'null' is not allowed by the type of the 'value' prop below.
   * 'undefined' leads to subtle bugs.
   */
  const [lotNumber, setLotNumber] = useState(recorded?.lot ?? item.lot ?? '');
  const partRestricted = isPartRestricted(part);
  const [showItemOverlay, setShowItemOverlay] = useState(false);

  useEffect(() => {
    setLotNumber(recorded?.lot ?? item.lot ?? '');
  }, [item.lot, recorded?.lot]);

  useEffect(() => {
    if (part?.tracking === 'lot') {
      const lot = recorded?.lot || item.lot;
      if (isEmptyValue((lot ?? '').trim())) {
        onRecordErrorsChanged({ lot: 'Empty Lot #' });
      } else {
        onRecordErrorsChanged({});
      }
    }
    // including 'onRecordErrorsChanged' causes an infinite render cycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [part?.tracking, recorded?.lot, item.lot]);

  const onChangePrefix = (option: SelectOption | null, itemId: string) => {
    const newPrefix = option?.value ?? '';
    if (newPrefix) {
      onAddLotNumber && onAddLotNumber(itemId, newPrefix).catch((err) => apm.captureError(err));
    } else {
      onClearLotNumber && onClearLotNumber(itemId).catch((err) => apm.captureError(err));
    }
  };

  const onChangeItemLot = (e: ChangeEvent<HTMLInputElement>) => {
    setShowItemOverlay(false);
    const lot = e.target.value;
    const values = {
      ...item,
      ...recorded,
      lot,
      prefix: '',
    };
    onRecordValuesChanged?.(values);
  };

  const onChangeAmount = (amount: number) => {
    const values = {
      ...item,
      ...recorded,
      amount,
    };
    onRecordValuesChanged?.(values);
  };

  const onChangeLocation = (location: Location) => {
    const values = {
      ...item,
      ...recorded,
      location_id: location ? location.id : '',
    };
    onRecordValuesChanged?.(values);
  };

  // For the run, initialize recorded values before signoff
  if (isEnabled && part?.tracking === 'none' && !recorded) {
    const values: RecordedItem = {
      ...item,
    };
    onRecordValuesChanged?.(values);
  }
  return (
    <>
      <tr className="h-10 text-sm border-b items-center">
        <td>
          <ComponentPartLabel teamId={teamId} component={item} part={part} />
        </td>
        <td>
          <div className="p-1">
            {!partRestricted && (
              <div className="w-full max-w-[8rem] flex items-center gap-x-1">
                <ItemQuantityInput
                  value={recorded?.amount ?? item.amount}
                  allowDecimalValue={part?.allow_decimal_quantities === true}
                  allowNull={false}
                  onChange={(value) => onChangeAmount(value as number)}
                  disabled={!isEnabled}
                  min={0}
                  placeholder="Quantity"
                  textSize="sm"
                />
                {part?.units && <UnitDisplay unit={part.units} />}
              </div>
            )}
          </div>
        </td>
        <td>
          <div className="p-1 pb-2 mt-[0.2rem]">
            {part?.tracking === 'lot' && (isEnabled || !showItemLink(recorded)) && !partRestricted && (
              <div className="relative">
                <FullNumberInput
                  type="lot_numbers"
                  autoNumbering={autoNumbering}
                  prefix={prefix}
                  onPrefixChange={(option) => onChangePrefix(option, item.id)}
                  value={lotNumber}
                  onInputBlur={onChangeItemLot}
                  onInputChange={(e) => setLotNumber(e.target.value)}
                  entityId={item.id}
                  onAdd={(itemId) => onAddLotNumber && onAddLotNumber(itemId, recorded?.prefix)}
                  onClear={(itemId) => onClearLotNumber && onClearLotNumber(itemId)}
                  disabled={!isEnabled}
                  useFormik={false}
                  onNumberInputClick={() => setShowItemOverlay(true)}
                  showCaret={checkedOutItems && checkedOutItems.length > 0}
                />
                {showItemOverlay && (
                  <ItemOverlay
                    items={checkedOutItems}
                    filter={lotNumber}
                    onSelectItem={(item) => {
                      item.lot && setLotNumber(item.lot);
                      setShowItemOverlay(false);
                    }}
                  />
                )}
              </div>
            )}
            {!isEnabled && showItemLink(recorded) && !partRestricted && (
              <CheckedInItemLink teamId={teamId} itemId={recorded?.item_id as string} trackingId={lotNumber || '--'} />
            )}
          </div>
        </td>
        <td>
          {part && !partRestricted && (
            <div className="max-w-[12rem]">
              <LocationSelect
                locationId={recorded?.location_id || ''}
                onChangeLocation={onChangeLocation}
                isDisabled={!isEnabled}
              />
            </div>
          )}
        </td>
        {isEnabled && canRemoveItem && (
          <td>
            <button type="button" className="secondary text-gray-400 disabled:text-gray-200" onClick={onRemoveItem}>
              <FontAwesomeIcon className="ml-1 group-hover:text-gray-500" icon="times-circle" />
            </button>
          </td>
        )}
      </tr>
      {/* Spacer for "add" button */}
      <div className="h-10" />
    </>
  );
};

export default FieldInputBuildItem;
