import Avatar from '../elements/Avatar';
import { useMemo } from 'react';
import Tooltip from '../elements/Tooltip';

const MAX_ICONS_TO_SHOW = 5;

const AvatarStack = ({ userIds }) => {
  const visibleIds = useMemo(() => (userIds ?? []).slice(0, MAX_ICONS_TO_SHOW), [userIds]);
  const hiddenIds = useMemo(() => (userIds ?? []).slice(MAX_ICONS_TO_SHOW), [userIds]);

  if (visibleIds.length === 0) {
    return <></>;
  }
  return (
    <div className="inline-flex -space-x-2">
      {visibleIds.map((userId) => (
        <Avatar key={userId} userId={userId} />
      ))}
      {hiddenIds.length > 0 && (
        <Tooltip content={hiddenIds.join(', ')}>
          <div className="h-6 w-6 pl-3 cursor-default flex justify-center items-center text-gray-400">
            +{hiddenIds.length}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default AvatarStack;
