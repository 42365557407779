import { useMemo } from 'react';
import Select from 'react-select';
import { Tool, ToolInstance } from 'shared/lib/types/api/manufacturing/tools/models';
import { NumberSelectOption as SelectOption } from '../../../lib/formik';
import { reactSelectStyles } from '../../../lib/styles';
import StatusIcons from './StatusIcons';
import { toolInstanceToSelectOption } from './utils';

type SelectToolInstanceProps = {
  availableToolInstances: Array<ToolInstance> | undefined;
  tool: Tool;
  toolInstance: ToolInstance | undefined;
  onToolInstanceChange: (toolInstanceOption: SelectOption) => void;
  isEnabled: boolean;
};

const SelectToolInstance = ({
  availableToolInstances,
  tool,
  toolInstance,
  onToolInstanceChange,
  isEnabled,
}: SelectToolInstanceProps) => {
  const toolInstanceOptions = useMemo(() => {
    if (!availableToolInstances) {
      return [];
    }
    return availableToolInstances.map(toolInstanceToSelectOption);
  }, [availableToolInstances]);

  const toolInstanceOption = useMemo(() => {
    if (!toolInstance) {
      return null;
    }
    return toolInstanceToSelectOption(toolInstance);
  }, [toolInstance]);

  return (
    <Select
      styles={reactSelectStyles}
      classNamePrefix="react-select"
      options={toolInstanceOptions}
      formatOptionLabel={(option: SelectOption) => {
        const toolInstance = availableToolInstances?.find((toolInstance) => toolInstance.id === option.value);
        return toolInstance && <StatusIcons tool={tool} toolInstance={toolInstance} showCheckedOutStatus={false} />;
      }}
      value={toolInstanceOption}
      onChange={onToolInstanceChange}
      isDisabled={!isEnabled}
      isClearable={true}
      placeholder="Search tool instances"
      aria-label="Select tool instance"
    />
  );
};

export default SelectToolInstance;
