import cloneDeep from 'lodash.clonedeep';
import { useMemo } from 'react';
import { BatchStepProps, KitItem, RunPartKitBlock, RunPartKitRecorded } from 'shared/lib/types/views/procedures';
import SubstepNumber from '../../components/SubstepNumber';
import { useRunContext } from '../../contexts/RunContext';
import { generateHiddenClassString } from '../../lib/styles';
import useParts from '../hooks/useParts';
import { asComponentPart } from '../lib/parts';
import FieldInputInventoryItem from './FieldInputInventoryItem';
import PartAndRevisionPusher from './PartAndRevisionPusher';
import inventoryUtil from '../lib/inventoryUtil';

const getPreviousTabRecorded = (itemIndex: number, previousTabContent?: RunPartKitBlock): KitItem | undefined => {
  if (!previousTabContent) {
    return;
  }
  const contentItem = previousTabContent.items[itemIndex];
  return previousTabContent.recorded?.items?.[contentItem.id];
};

type PartKitProps = {
  content: RunPartKitBlock;
  contentIndex: number;
  recorded?: RunPartKitRecorded;
  blockLabel?: string;
  onRecordValuesChanged?: (contentId: string, values: RunPartKitRecorded) => void;
  isEnabled: boolean;
  isHidden: boolean;
  isStepComplete: boolean;
  teamId: string;
  batchProps?: BatchStepProps;
  projectId?: string;
};

const PartKit = ({
  content,
  contentIndex,
  recorded,
  blockLabel,
  onRecordValuesChanged,
  isEnabled,
  isHidden,
  isStepComplete,
  teamId,
  batchProps,
  projectId,
}: PartKitProps) => {
  const { getPreviousStepInBatch } = useRunContext();

  const partIdsInBlock = useMemo(() => inventoryUtil.collectPartIdsInBlock(content, recorded), [content, recorded]);

  const { parts, getPart, getPartByRevisionId } = useParts({
    partIds: partIdsInBlock,
    includeAllReleasedRevisions: true,
  });

  const previousTabContent = useMemo(() => {
    if (isStepComplete || !batchProps) {
      return;
    }

    const previousTab = getPreviousStepInBatch(batchProps);
    if (!previousTab) {
      return;
    }
    const previousTabContent = previousTab.content[contentIndex];
    if (previousTabContent.type !== 'part_kit' || previousTabContent.items.length !== content.items.length) {
      return;
    }
    return previousTabContent;
  }, [isStepComplete, batchProps, getPreviousStepInBatch, contentIndex, content.items.length]);

  const selectedItemIds: string[] = useMemo(() => {
    const recordedPartKit = recorded as RunPartKitRecorded | undefined;

    const recordedKitItems: KitItem[] = [];
    if (recordedPartKit?.items) {
      recordedKitItems.push(...Object.values(recordedPartKit.items));
    }
    if (recordedPartKit?.added_items) {
      recordedKitItems.push(...recordedPartKit.added_items);
    }

    return recordedKitItems
      .filter((recordedItem) => !!recordedItem.item_id)
      .map((recordedItem) => recordedItem.item_id as string);
  }, [recorded]);

  const recordItemValuesChanged = (item, values) => {
    const updated = {
      ...recorded,
      items: {
        ...recorded?.items,
        [item.id]: values,
      },
    };
    onRecordValuesChanged?.(content.id, updated);
  };

  const recordAddedItemValuesChanged = (addedItemIndex, values) => {
    const updated = cloneDeep(recorded);
    updated.added_items[addedItemIndex] = values;
    onRecordValuesChanged?.(content.id, updated);
  };

  const onPushPart = (part) => {
    const updated = recorded ? cloneDeep(recorded) : {};
    updated.items = updated.items || {};
    updated.added_items = updated.added_items || [];
    const component = asComponentPart(part, 1);
    updated.added_items.push({ ...component, item_id: null });
    onRecordValuesChanged?.(content.id, updated);
  };

  const onRemoveAddedItem = (addedItemIndex) => {
    const updated = cloneDeep(recorded);
    updated.added_items = updated.added_items.filter((_, index) => {
      return index !== addedItemIndex;
    });
    onRecordValuesChanged?.(content.id, updated);
  };

  if (!parts) {
    return null;
  }

  return (
    <tr>
      <td></td>
      <td colSpan={2}>
        <div className={generateHiddenClassString('', isHidden)} />
        <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
          <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
          <div className="flex items-start w-full py-1 mr-8">
            {/* Kit items */}
            <table className="w-full table-fixed">
              <thead>
                <tr>
                  <td>
                    <div className="p-1">
                      <label htmlFor="components" className="text-sm font-medium uppercase">
                        Parts for Check-Out
                      </label>
                    </div>
                  </td>
                  <td>
                    <div className="p-1">
                      <span className="text-sm font-medium uppercase">Quantity</span>
                    </div>
                  </td>
                  <td>
                    <div className="p-1">
                      <span className="text-sm font-medium uppercase">Inventory</span>
                    </div>
                  </td>
                  {/* Placeholder for remove button */}
                  <td className="w-6"></td>
                </tr>
              </thead>
              <tbody>
                {content?.items.map((item, index) => {
                  const part = getPartByRevisionId(item.revision_id ?? '') ?? getPart(item.part_id);
                  return (
                    <FieldInputInventoryItem
                      key={index}
                      item={item}
                      recorded={recorded?.items?.[item.id]}
                      part={part}
                      isEnabled={isEnabled}
                      teamId={teamId}
                      onRecordValuesChanged={(values) => recordItemValuesChanged(item, values)}
                      isStepComplete={isStepComplete}
                      selectedItemIds={selectedItemIds}
                      canRemove={false}
                      onRemove={() => {
                        /* no-op */
                      }}
                      previousTabRecorded={getPreviousTabRecorded(index, previousTabContent)}
                    />
                  );
                })}
                {recorded?.added_items?.map((item, index) => {
                  const part = getPartByRevisionId(item.revision_id ?? '') ?? getPart(item.part_id);
                  return (
                    <FieldInputInventoryItem
                      item={item}
                      recorded={item}
                      part={part}
                      isEnabled={isEnabled}
                      teamId={teamId}
                      onRecordValuesChanged={(values) => recordAddedItemValuesChanged(index, values)}
                      isStepComplete={isStepComplete}
                      selectedItemIds={selectedItemIds}
                      canRemove={true}
                      onRemove={() => onRemoveAddedItem(index)}
                    />
                  );
                })}
                {!isStepComplete && (
                  <tr>
                    <td colSpan={3}>
                      <PartAndRevisionPusher projectId={projectId} onPush={onPushPart} isDisabled={!isEnabled} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default PartKit;
