import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { reactSelectStyles } from '../../lib/styles';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { FieldInputProps, FormikProps } from 'formik';
import { SnippetType, Snippet } from '../../lib/views/settings';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import ProjectTreeSelector, { EntityWithProject } from '../../elements/ProjectTreeSelector';

type SnippetEntity = Snippet & EntityWithProject;

interface SnippetFieldProps {
  field: FieldInputProps<Snippet>;
  form: FormikProps<Snippet>;
  snippetType: SnippetType;
  isTestSnippet: boolean;
}

const SnippetField = ({ field, form, snippetType, isTestSnippet }: SnippetFieldProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [snippets, setSnippets] = useState<Array<Snippet>>([]);

  const useProjectBasedSelector = useMemo(() => {
    return snippets.some((snippet) => snippet.project_id);
  }, [snippets]);

  useEffect(() => {
    if (!services.settings) {
      return;
    }
    services.settings
      .listSnippets({ isTestSnippet })
      .then((snippets) => {
        const filteredSnippets = snippets.filter((snippet: Snippet) => snippet.snippet_type === snippetType);
        setSnippets(filteredSnippets);
      })
      .catch(() => {
        /* Ignored */
      });
  }, [services.settings, snippetType, isTestSnippet]);

  const onChangeHandler = useCallback(
    (option) => {
      const snippet = snippets.find((snippet) => snippet._id === option.value);
      if (snippet) {
        form.setFieldValue(field.name, snippet);
      }
    },
    [snippets, form, field]
  );

  const snippetOptions = useMemo(() => {
    return snippets.map((snippet) => ({
      value: snippet._id,
      label: snippet.name,
    }));
  }, [snippets]);

  const selectValue = field.value
    ? {
        value: field.value._id,
        label: field.value.name,
      }
    : null;

  // Project-aware snippet selector
  const entities = useMemo<Array<SnippetEntity>>(() => {
    return snippets.map((snippet) => ({
      ...snippet,
      projectId: snippet.project_id,
      id: snippet._id,
    }));
  }, [snippets]);

  const onSelect = useCallback(
    (key?: string | number, snippet?: SnippetEntity) => {
      if (snippet) {
        form.setFieldValue(field.name, snippet);
      }
    },
    [field.name, form]
  );

  const labelFormatter = (snippet: SnippetEntity) => snippet.name;

  return (
    <div>
      {useProjectBasedSelector ? (
        <ProjectTreeSelector entities={entities} labelFormatter={labelFormatter} onSelect={onSelect} />
      ) : (
        <Select
          isSearchable={true}
          value={selectValue}
          options={snippetOptions}
          classNamePrefix="react-select"
          onChange={onChangeHandler}
          placeholder="Search by name"
          styles={reactSelectStyles}
        />
      )}
    </div>
  );
};

export default SnippetField;
