import React, { useCallback } from 'react';
import { ParentReferenceType, ProcedureLinkBlock, Run } from 'shared/lib/types/views/procedures';
import BlockProcedureLink from '../../components/Blocks/BlockProcedureLink';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import { ProcedureContextProvider } from '../../contexts/ProcedureContext';
import { RunContextProvider } from '../../contexts/RunContext';
import { VIEW_MODES } from '../../components/FieldSetViewModeEditSelect';
import useScheduleTrack from '../hooks/useScheduleTrack';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useDispatch } from 'react-redux';
import { startRun } from '../../contexts/runsSlice';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import apm from '../../lib/apm';

const PROCEDURE_SHELL = {
  _id: '',
  code: '',
  name: '',
  description: '',
  starttime: '',
  sections: [
    {
      id: 'steps',
      name: 'steps',
      steps: [
        {
          id: 'stub_step_id',
          name: 'sub_step',
          content: [],
          signoffs: [],
        },
      ],
    },
  ],
};

interface EventProcedureLinkProps {
  event: Event;
  multiLine?: boolean;
}

const EventProcedureLink = ({ event, multiLine = true }: EventProcedureLinkProps) => {
  const scheduleTrack = useScheduleTrack();
  const dispatch = useDispatch();
  const { currentTeamId, services }: { currentTeamId: string; services: DatabaseServices } = useDatabaseServices();

  const handleStartRun = useCallback(
    async (contentIndex: number, run: Run) => {
      if (!event) {
        return;
      }
      const updatedEvent = cloneDeep(event);
      updatedEvent.run_id = run._id;
      if (updatedEvent.operation) {
        run.operation = updatedEvent.operation;
      }
      if (!updatedEvent.start && run.starttime) {
        updatedEvent.start = DateTime.fromJSDate(new Date(run.starttime)).toUTC();
      }

      let updatedEventId = event.id;
      try {
        if (updatedEvent.recurrence_id) {
          updatedEvent.recurrence_date = updatedEvent.start;
          updatedEventId = await services.events.createEvent(updatedEvent, true);
        } else {
          await services.events.updateEvent(updatedEvent.id, updatedEvent, true);
        }
      } catch (err) {
        apm.captureError(err);
      }

      run.parent_reference = {
        id: updatedEventId,
        type: ParentReferenceType.Event,
      };

      scheduleTrack('Procedure Run');
      dispatch(
        startRun({
          teamId: currentTeamId,
          run,
        })
      );
    },
    [dispatch, currentTeamId, scheduleTrack, event, services.events]
  );

  const procedureIds: ProcedureLinkBlock = {
    id: 'event_procedure_link',
    procedure: event.procedure_id || '',
    section: event.procedure_section_id || '',
    run: event.run_id || '',
    type: 'procedure_link',
  };
  const canAddRun = !event.operation || event.operation.state === 'running';

  return (
    <ProcedureContextProvider procedure={PROCEDURE_SHELL} scrollTo={undefined}>
      <RunContextProvider
        run={PROCEDURE_SHELL}
        viewMode={VIEW_MODES.LIST}
        isPreviewMode={false}
        showStepAction=""
        setShowStepAction={() => {
          /* no-op */
        }}
        currentStepId=""
        setCurrentStepId={() => {
          /* no-op */
        }}
        telemetryParameters={[]}
        fetchedTelemetryParameters={[]}
        optionalParameters={{
          forceUserToParticipate: true,
        }}
      >
        <BlockProcedureLink
          contentIndex={0}
          docId=""
          docState="running"
          operation=""
          link={procedureIds}
          onStartLinkedRun={handleStartRun}
          isHidden={false}
          isEnabled={canAddRun}
          showLabels={false}
          multiLine={multiLine}
          sourceName={event.name}
        />
      </RunContextProvider>
    </ProcedureContextProvider>
  );
};

export default EventProcedureLink;
