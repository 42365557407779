import Label from '../../components/Label';

type RevisionProps = {
  revision: number | string;
  size?: 'sm' | 'med';
  ml?: number;
  prefix?: boolean;
};

const Revision = ({ revision, size = 'med', ml = 2, prefix = true }: RevisionProps) => {
  const revisionText = `${prefix ? 'Rev ' : ''}${revision}`;
  return (
    <div className={`ml-${ml} leading-none`}>
      <Label text={revisionText} color="border border-gray-300 rounded-xl" verticalPadding={size === 'med'} />
    </div>
  );
};

export default Revision;
