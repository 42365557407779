import React, { createContext, useContext } from 'react';
import { FrontendEvent as Event } from 'shared/schedule/types/event';

interface EventsContextProps {
  events: Event[];
  children: React.ReactNode;
}

const EventsContext = createContext<Event[] | undefined>(undefined);

export const useEvents = () => {
  const context = useContext(EventsContext);
  if (!context) {
    throw new Error('useEvents must be used within an EventsProvider');
  }
  return context;
};

export const EventsProvider = ({ events, children }: EventsContextProps) => {
  return <EventsContext.Provider value={events}>{children}</EventsContext.Provider>;
};
